import { Buffer } from 'buffer';
interface JwtPayload {
  sub: string;
  iss: string;
  aud: string;
  groups: string[];
  userId: number;
  exp: number;
}

const isValidJwtPayload = (
  payload: string | JwtPayload | null
): payload is JwtPayload => {
  return (
    payload !== null &&
    typeof payload !== 'string' &&
    (payload as JwtPayload).groups !== undefined
  );
};

const decodeJwt = (token: string): JwtPayload | null => {
  const body = token.split('.')[1];
  const buff = Buffer.from(body, 'base64').toString();
  const decoded = JSON.parse(buff);

  if (isValidJwtPayload(decoded)) {
    return decoded;
  }
  return null;
};

export const decodeToken = (token: string): JwtPayload | null => {
  const decoded = decodeJwt(token);
  if (isValidJwtPayload(decoded)) {
    return decoded;
  }
  return null;
};
