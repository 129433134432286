import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RefObject } from 'react';
import {
  Cell,
  Column,
  TableCellProps,
  useTable,
  TableHeaderProps,
  ColumnInstance,
} from 'react-table';

export interface CustomTableProps {
  /**
   * @property {boolean} collapse - if true, the cell doesn't grow in width
   */
  collapse?: boolean;
  /**
   * @property {boolean} wrap - controls wether the text in the cell should wrap or not
   */
  wrap?: boolean;
}

export interface CustomTableHeaderProps extends CustomTableProps {}
export interface CustomTableCellProps extends CustomTableProps {}

export interface TableProps<T extends {}> {
  data: T[];
  columns: Column<T>[];
  getHeaderProps?: (
    headerInfo: ColumnInstance<T>
  ) => Partial<Omit<TableHeaderProps, 'key'>> & CustomTableHeaderProps;
  getCellProps?: (
    cellInfo: Cell<T>
  ) => Partial<Omit<TableCellProps, 'key'>> & CustomTableCellProps;
  isLoading: boolean;
  small?: boolean;
  className?: string;
  bottomRef?: RefObject<HTMLTableRowElement>;
  rootRef?: RefObject<HTMLTableSectionElement>;
}

const defaultPropsGetter = () => ({});

const Table = <T extends {}>({
  columns,
  data,
  getHeaderProps = defaultPropsGetter,
  getCellProps = defaultPropsGetter,
  isLoading,
  small,
  className,
  bottomRef,
  rootRef,
}: TableProps<T>): JSX.Element => {
  const table = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = table;

  return (
    <div className="max-w-full max-h-full overflow-auto flex-1 text-sm min-h-[300px]">
      <table
        {...getTableProps()}
        className="w-full relative max-h-full overflow-auto"
      >
        <thead className="w-full">
          <tr
            className={
              'bg-secondary-default ' + (isLoading ? 'animate-pulse' : '')
            }
          >
            {headers.map((column) => {
              const { key: headerKey, ...restColumn } = column.getHeaderProps();
              const {
                collapse,
                className: headerClassName,
                ...restProps
              } = getHeaderProps(column);
              return (
                <th
                  key={headerKey}
                  {...restProps}
                  {...restColumn}
                  className={
                    'm-0 p-3 text-left whitespace-nowrap sticky top-0 bg-secondary-default text-white ' +
                    headerClassName
                  }
                >
                  {column.render('Header')}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody {...getTableBodyProps} className="w-full">
          {rows.length ? (
            rows.map((row) => {
              prepareRow(row);
              const { key: rowKey, ...restRowProps } = row.getRowProps();
              return (
                <tr
                  key={rowKey}
                  {...restRowProps}
                  className="bg-white cursor-default hover:bg-primary-light hover:bg-opacity-20 border-b h-[40px]"
                >
                  {row.cells.map((cell) => {
                    const { key: cellKey, ...restCell } = cell.getCellProps();
                    const {
                      collapse,
                      className: rowClassName,
                      wrap,
                      ...restProps
                    } = getCellProps(cell);
                    return (
                      <td
                        key={cellKey}
                        {...restProps}
                        {...restCell}
                        className={`m-0 px-3 w-auto text-left ${
                          wrap ? '' : 'whitespace-nowrap'
                        }`}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : isLoading ? (
            <tr>
              <td
                colSpan={columns.length}
                className="m-0 p-3 w-auto text-center text-secondary-default"
              >
                <FontAwesomeIcon spin icon={faCircleNotch} size="2x" />
              </td>
            </tr>
          ) : (
            <tr>
              <td
                colSpan={columns.length}
                className="m-0 p-3 w-auto text-center"
              >
                Nincs találat
              </td>
            </tr>
          )}
          <tr ref={bottomRef}></tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
