export enum PrizeStatus {
  WAITING = 'WAITING',
  COLLECTED = 'COLLECTED',
  EXPIRED = 'EXPIRED',
}

export interface IPrize {
  id: number;
  cardNumber: string;
  message: string;
  status: PrizeStatus;
  uploadDate: string;
  code: string;
  email: string;
}
