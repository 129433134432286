import { isSuccessResponse } from '../../../../api/apiClient';
import { useDeleteImage, useUploadImage } from '../../../../api/tiles';
import { useModal } from '../../../../context/modalContext';
import useNotifications from '../../../../hooks/useNotifications';
import { ImageInfo } from '../../../../types/tiles';
import { assertValue } from '../../../../utils/helpers';
import Button from '../../../ui/Button/Button';
import FileUpload from '../../../ui/FileUpload/FileUpload';
import { useTileInfo } from './useTileInfo';

export interface ITileInfoProps {
  imageInfo: ImageInfo;
}

const TileInfo = ({ imageInfo }: ITileInfoProps) => {
  const { closeModal } = useModal();
  const notifications = useNotifications();
  const {
    src,
    clearUploadFile,
    onChangeHandler,
    dragOverHandler,
    dropHandler,
    setDragOver,
    setIsNewUpload,
    dragOver,
    isNewUpload,
    inputRef,
    imageFile,
  } = useTileInfo(imageInfo);

  const deleteImageMutation = useDeleteImage();
  const uploadImageMutation = useUploadImage();

  const handleDeleteImage = () => {
    assertValue(imageInfo.id);
    deleteImageMutation.mutate(imageInfo.id, {
      onSuccess: () => closeModal(),
    });
  };

  const handleUploadImage = () => {
    assertValue(imageFile);
    uploadImageMutation.mutate(
      {
        image: imageFile,
        position: imageInfo.position,
      },
      {
        onSuccess: (response) => {
          if (isSuccessResponse(response)) {
            closeModal();
          } else {
            notifications.handleErrorResponse(response);
          }
        },
      }
    );
  };

  return (
    <div className="container h-full mx-auto max-w-screen-lg">
      <div
        className="relative h-full flex flex-col items-center bg-white shadow-xl rounded-md"
        onDragOver={dragOverHandler}
        onDragEnter={dragOverHandler}
        onDragLeave={() => setDragOver(false)}
        onDrop={dropHandler}
      >
        {src ? (
          <div className="max-h-[500px] h-full p-8">
            <img
              className="max-h-full max-w-full object-scale-down"
              src={src}
            />
          </div>
        ) : (
          <FileUpload
            buttonText="Kép kiválasztása"
            uploadBoxText="Húzza a képet a négyzetbe, vagy"
            dragOver={dragOver}
            inputRef={inputRef}
            onChangeHandler={onChangeHandler}
          />
        )}

        {isNewUpload ? (
          <div className="flex w-full justify-between px-2">
            <Button
              disabled={!src}
              className="w-5/12"
              onClick={handleUploadImage}
              loading={uploadImageMutation.isLoading}
            >
              Feltöltés
            </Button>
            <Button
              disabled={!src}
              onClick={clearUploadFile}
              className="w-5/12"
              variant="secondary"
            >
              Mégsem
            </Button>
          </div>
        ) : (
          <div className="flex w-full justify-between px-2">
            <Button onClick={() => setIsNewUpload(true)} className="w-5/12">
              Kép csere
            </Button>
            <Button
              onClick={handleDeleteImage}
              variant="secondary"
              className="w-5/12"
              loading={deleteImageMutation.isLoading}
            >
              Kép törlése
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TileInfo;
