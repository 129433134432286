import { useModal } from '../../../../context/modalContext';
import { ImageInfo } from '../../../../types/tiles';
import ModalOverlay from '../../../ui/Overlays/ModalOverlay/ModalOverlay';
import TileInfo from './TileInfo';
import useTile from './useTile';

export interface ITileProps {
  imageInfo: ImageInfo;
}

const Tile = ({ imageInfo }: ITileProps) => {
  const { src, fileName } = useTile(imageInfo);
  const { openModalWithContent } = useModal();

  return (
    <div
      onClick={() =>
        openModalWithContent(
          <ModalOverlay>
            <TileInfo imageInfo={imageInfo} />
          </ModalOverlay>
        )
      }
      className="flex flex-col p-4 w-full cursor-pointer"
    >
      <div className="h-full max-h-[400px] flex flex-col items-center justify-center">
        <figure className="relative rounded-xl overflow-hidden">
          <img
            className="rounded-xl border-2 border-secondary-default shadow-lg shadow-secondary-default/30 p-1 pb-6 w-full h-auto sm:w-auto sm:h-full object-fit"
            src={src ? src : 'https://fakeimg.pl/350x500/?text=Nincs kép'}
          />
          <figcaption className="absolute bottom-0 w-full text-center bg-black bg-opacity-60 text-white">
            {fileName}
          </figcaption>
        </figure>
      </div>
    </div>
  );
};

export default Tile;
