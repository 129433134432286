import Button from '../Button/Button';

export interface IFileUploadProps {
  inputRef: React.RefObject<HTMLInputElement>;
  dragOver: boolean;
  onChangeHandler: React.ChangeEventHandler<HTMLInputElement>;
  uploadBoxText: string;
  buttonText: string;
}

const FileUpload = ({
  dragOver,
  inputRef,
  onChangeHandler,
  uploadBoxText,
  buttonText,
}: IFileUploadProps) => {
  return (
    <div className="h-full overflow-auto p-2 sm:p-8 w-full flex flex-col">
      <div className="border-dashed border-4 h-full border-secondary-default py-12 flex flex-col justify-center items-center">
        {dragOver ? (
          <div
            id="overlay"
            className="w-full h-full absolute top-0 left-0 pointer-events-none z-50 flex flex-col items-center justify-center rounded-md"
          >
            <i>
              <svg
                className="fill-current w-12 h-12 mb-3 text-secondary-default"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479-1.092l4 4h-3v4h-2v-4h-3l4-4z" />
              </svg>
            </i>
            <p className="text-lg text-primary-default">Feltöltés</p>
          </div>
        ) : (
          <>
            <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center text-center">
              {uploadBoxText}
            </p>
            <input
              ref={inputRef}
              type="file"
              multiple
              className="hidden"
              onChange={onChangeHandler}
            />
            <Button onClick={() => inputRef.current?.click()}>
              {buttonText}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
