export enum MessageStatus {
  UNREAD = 'UNREAD',
  READ = 'READ',
}

export interface IMessage {
  id: number;
  cardNumber: string;
  header: string;
  message: string;
  status: MessageStatus;
  uploadDate: string;
  email: string;
}
