import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import Loader from '../components/ui/Loader/Loader';
import { AbilityProvider } from './abilityContext';
import { AuthProvider } from './authContext';
import { ModalProvider } from './modalContext';
import NotificationProvider from './notificationContext';
import ClientProvider from './queryClientContext';
import { SessionProvider } from './sessionContext';

export interface IContextProvider {
  children: React.ReactNode;
}

const AppProviders = ({ children }: IContextProvider) => {
  return (
    <React.Suspense fallback={<Loader />}>
      <SessionProvider>
        <NotificationProvider>
          <ClientProvider>
            <AuthProvider>
              <AbilityProvider>
                <ModalProvider>
                  <Router>{children}</Router>
                  <ReactQueryDevtools initialIsOpen={true} />
                </ModalProvider>
              </AbilityProvider>
            </AuthProvider>
          </ClientProvider>
        </NotificationProvider>
      </SessionProvider>
    </React.Suspense>
  );
};

export default AppProviders;
