import { createMaps } from '../../../../utils/helpers';

const variantMaps = createMaps({
  red: 'bg-dashboardCard-red',
  green: 'bg-dashboardCard-green',
  blue: 'bg-dashboardCard-blue',
  yellow: 'bg-dashboardCard-yellow',
});

export type CardVariant = keyof typeof variantMaps;

export interface ICardProps {
  title: string;
  value: string | number;
  variant: CardVariant;
  isFetching: boolean;
}

const Card = ({ title, value, variant, isFetching }: ICardProps) => {
  return (
    <div className={`p-4 rounded text-white font-bold ${variantMaps[variant]}`}>
      <div className="flex justify-between">
        <p className="text-lg">{title}</p>
      </div>
      <hr className="mt-2 mb-4" />
      <p className="text-4xl">{value}</p>
    </div>
  );
};

export default Card;
