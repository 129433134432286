import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { AbilityActions } from '../../../config/ability';
import { AppRoutes } from '../../../constants/routes';
import { Can } from '../../../context/abilityContext';
import Button from '../../ui/Button/Button';
import FileUpload from '../../ui/FileUpload/FileUpload';
import useFileUpload from '../../ui/FileUpload/useFileUpload';
import DatePicker from '../../ui/Form/Controls/Date/DatePicker';
import Form from '../../ui/Form/Form';
import PageContainer from '../../ui/PageContainer/PageContainer';
import Table from '../../ui/Table/Table';
import usePrizes, { IPrizesFilter } from './usePrizes';

const Prizes = () => {
  const [uploadFile, setUploadFile] = useState<File>();
  const handleSetFile = (file?: File) => {
    setUploadFile(file);
  };
  const {
    inputRef,
    dragOver,
    clearUploadFile,
    dragOverHandler,
    dropHandler,
    onChangeHandler,
    setDragOver,
  } = useFileUpload({ setFile: handleSetFile });

  const {
    data,
    columns,
    isLoading,
    handleFileDownload,
    handleFileUpload,
    setFilters,
  } = usePrizes();

  return (
    <PageContainer title="Nyeremények kezelése">
      <div>
        <Button variant="info">
          <FontAwesomeIcon icon={faDownload} className="mr-2" />
          <a
            target="_blank"
            href="/files/nyeremenyek_pelda.xlsx"
            className="text-white"
          >
            Példa Excel fájl letöltése
          </a>
        </Button>
      </div>
      <div className="flex w-full">
        <div
          className="relative flex-1 h-52"
          onDragOver={dragOverHandler}
          onDragEnter={dragOverHandler}
          onDragLeave={() => setDragOver(false)}
          onDrop={dropHandler}
        >
          {!uploadFile ? (
            <FileUpload
              inputRef={inputRef}
              dragOver={dragOver}
              uploadBoxText="Húzza ide a fájlt a csatoláshoz, vagy"
              buttonText="Böngésszen"
              onChangeHandler={onChangeHandler}
            />
          ) : (
            <div className="flex flex-1 flex-wrap justify-end items-end px-2 py-4">
              <div className="w-full flex justify-between">
                <div>{uploadFile.name}</div>
                <div>{(uploadFile.size / (1024 * 1024)).toFixed(2) + 'MB'}</div>
              </div>
              <Button
                disabled={!uploadFile}
                className="mr-2"
                onClick={() =>
                  handleFileUpload(uploadFile, () => setUploadFile(undefined))
                }
              >
                Feltöltés
              </Button>
              <Button
                disabled={!uploadFile}
                variant="secondary"
                onClick={clearUploadFile}
              >
                Mégsem
              </Button>
            </div>
          )}
        </div>
      </div>
      <Form<IPrizesFilter>
        onSubmit={() => {}}
        onControlValueChange={(values) => setFilters(values)}
      >
        <div className="grid gap-x-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
          <DatePicker name="dateFrom" label="Dátumtól" />
          <div className="flex items-end">
            <Can do={AbilityActions.READ} on={AppRoutes.PRIZES}>
              <Button
                onClick={handleFileDownload}
                className="mb-5"
                disabled={!data.length}
              >
                <FontAwesomeIcon icon={faDownload} className="mr-2" />
                Eredmény letöltése
              </Button>
            </Can>
          </div>
        </div>
      </Form>
      <Table
        columns={columns}
        data={data}
        isLoading={isLoading}
        getHeaderProps={(headerInfo) => {
          switch (headerInfo.id) {
            case 'download':
              return { className: 'w-20' };
            case 'cardNumber':
              return { className: 'w-40' };
            case 'uploadDate':
              return { className: 'w-32' };
            case 'status':
              return { className: 'w-20' };
            case 'email':
              return { className: 'w-64' };
            default:
              return {};
          }
        }}
        getCellProps={(cellInfo) => {
          switch (cellInfo.column.id) {
            case 'message':
              return { wrap: true };
            default:
              return {};
          }
        }}
      />
    </PageContainer>
  );
};

export default Prizes;
