import React from 'react';
import ErrorFeedback from '../ErrorFeedback/ErrorFeedback';

export interface ControlProps {
  children: React.ReactElement;
  label: string;
  name: string;
  error: string;
}

export const BASE_INPUT_CLASS =
  'border border-primary-light text-primary-default text-md py-1 px-2 placeholder:text-primary-light outline-0 focus:shadow-inputOutline bg-white rounded disabled:text-primary-light';
export const INVALID_INPUT_CLASS = ' bg-red-50 text-red-600 border-red-600';

const Control = (props: ControlProps) => {
  const { label, children, name, error } = props;

  return (
    <div className="flex flex-col mt-1 sm:mt-1 mx-0 mb-3 sm:mb-5 relative">
      <label
        htmlFor={name}
        className="text-base flex items-center text-text-default font-bold"
      >
        {label}
      </label>
      {React.cloneElement(children, {
        name,
        className:
          BASE_INPUT_CLASS +
          (error ? INVALID_INPUT_CLASS : '') +
          ` ${children.props.className}`,
      })}
      {error ? <ErrorFeedback message={error} /> : null}
    </div>
  );
};

export default Control;
