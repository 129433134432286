import React, { useMemo, useState } from 'react';
import Loader from '../components/ui/Loader/Loader';
import { IContextProvider } from './appProviders';

export interface ISessionContext {
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
}

const SessionContext = React.createContext<ISessionContext | undefined>(
  undefined
);
SessionContext.displayName = 'SessionContext';

const SessionProvider = (props: IContextProvider) => {
  const [token, setToken] = useState<string | null>(null);

  const values = useMemo(() => ({ token, setToken }), [token, setToken]);

  return (
    <React.Suspense fallback={<Loader />}>
      <SessionContext.Provider value={values} {...props} />
    </React.Suspense>
  );
};

const useSession = () => {
  const context = React.useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSession must be used within an SessionProvider');
  }
  return context;
};

export { SessionProvider, useSession };
