import { useMemo, useState } from 'react';
import { Column } from 'react-table';
import { isSuccessResponse } from '../../../api/apiClient';
import { useGetMessages, useUploadMessages } from '../../../api/messages';
import {
  getOptionLabel,
  MESSAGE_STATUS_OPTIONS,
} from '../../../constants/options';
import useNotifications from '../../../hooks/useNotifications';
import { IMessage } from '../../../types/messages';
import { ISortOptions, Order } from '../../../types/table';
import { assertValue, formatDate } from '../../../utils/helpers';
import SortableHeader from '../../ui/Table/components/SortableHeader';
import { FileUploadHandler } from '../Prizes/usePrizes';

const useMessages = () => {
  const [sort, setSort] = useState<ISortOptions<IMessage>>({
    order: 'DESC',
    orderBy: 'uploadDate',
  });
  const { data, isLoading, isFetching } = useGetMessages({ sort });
  const notifications = useNotifications();
  const uploadMessageMutation = useUploadMessages();

  const handleFileUpload: FileUploadHandler = (uploadFile, onSuccess) => {
    assertValue(uploadFile);
    uploadMessageMutation.mutate(uploadFile, {
      onSuccess: (response) => {
        if (!isSuccessResponse(response)) {
          notifications.handleExcelErrorResponse(response, {
            0: 'Törzsvásárlói azonosító',
            1: 'Üzenet címe',
            2: 'Üzenet szövege',
          });
        }
        if (onSuccess) onSuccess();
      },
    });
  };

  const toggleSort = (column: keyof IMessage) => {
    setSort((prevSort) => {
      let order: Order = 'DESC';
      if (prevSort.orderBy === column) {
        order = prevSort.order === 'ASC' ? 'DESC' : 'ASC';
      }
      return {
        orderBy: column,
        order,
      };
    });
  };

  const tableData = data && isSuccessResponse(data) ? data.list : [];

  const columns = useMemo<Column<IMessage>[]>(
    () => [
      {
        accessor: 'cardNumber',
        Header: (headerProps) => (
          <SortableHeader
            currentSortId={sort.orderBy}
            currentSortOrder={sort.order}
            id={headerProps.column.id}
            label="Törzsvásárlói azonosító"
            handleClick={() =>
              toggleSort(headerProps.column.id as keyof IMessage)
            }
          />
        ),
      },
      {
        accessor: 'email',
        Header: (headerProps) => (
          <SortableHeader
            currentSortId={sort.orderBy}
            currentSortOrder={sort.order}
            id={headerProps.column.id}
            label="E-mail cím"
            handleClick={() =>
              toggleSort(headerProps.column.id as keyof IMessage)
            }
          />
        ),
      },
      {
        accessor: 'uploadDate',
        Header: (headerProps) => (
          <SortableHeader
            currentSortId={sort.orderBy}
            currentSortOrder={sort.order}
            id={headerProps.column.id}
            label="Feltöltés dátuma"
            handleClick={() =>
              toggleSort(headerProps.column.id as keyof IMessage)
            }
          />
        ),
        Cell: ({ value }) => formatDate(value, { timeStyle: undefined }),
      },
      {
        accessor: 'header',
        Header: (headerProps) => (
          <SortableHeader
            currentSortId={sort.orderBy}
            currentSortOrder={sort.order}
            id={headerProps.column.id}
            label="Üzenet címe"
            handleClick={() =>
              toggleSort(headerProps.column.id as keyof IMessage)
            }
          />
        ),
      },
      {
        accessor: 'message',
        Header: (headerProps) => (
          <SortableHeader
            currentSortId={sort.orderBy}
            currentSortOrder={sort.order}
            id={headerProps.column.id}
            label="Üzenet szövege"
            handleClick={() =>
              toggleSort(headerProps.column.id as keyof IMessage)
            }
          />
        ),
      },
      {
        accessor: 'status',
        Header: (headerProps) => (
          <SortableHeader
            currentSortId={sort.orderBy}
            currentSortOrder={sort.order}
            id={headerProps.column.id}
            label="Státusz"
            handleClick={() =>
              toggleSort(headerProps.column.id as keyof IMessage)
            }
          />
        ),
        Cell: ({ value }) => getOptionLabel(value, MESSAGE_STATUS_OPTIONS),
      },
    ],
    [sort]
  );

  const totalCount: number | null =
    data && isSuccessResponse(data) ? data.list.length : 0;

  return {
    handleFileUpload,
    data: tableData,
    columns,
    isLoading: isLoading || isFetching,
    totalCount: totalCount,
  };
};

export default useMessages;
