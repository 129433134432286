import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { ApiCache } from '../constants/cache';
import { ApiRoutes } from '../constants/routes';
import { useClient, User } from '../context/authContext';
import { HttpMethods, isSuccessResponse } from './apiClient';
import qs from 'qs';
import { getQueryWithParams } from '../utils/helpers';
import { ISortOptions } from '../types/table';
import { CardType, Roles } from '../constants/user';

export interface IUseGetUsersResponse {
  list: User[];
  totalItems: number;
}

export interface IUseGetUsersRequest {
  itemsPerPage?: number;
  filters: Partial<User>;
  sort: Partial<ISortOptions<User>>;
}

const useGetUsersInfinite = ({
  itemsPerPage = 50,
  filters,
  sort,
}: IUseGetUsersRequest) => {
  const client = useClient();
  const filterQuery = getQueryWithParams(filters, true);
  const sortQuery = getQueryWithParams(sort);
  const params = qs.stringify(filterQuery) + '&' + qs.stringify(sortQuery);

  return useInfiniteQuery(
    [ApiCache.USERS, filterQuery, sortQuery],
    ({ pageParam = 1 }) =>
      client<IUseGetUsersResponse>(
        `${ApiRoutes.GET_USERS}?page=${pageParam}&itemsPerPage=${itemsPerPage}&${params}`,
        {
          method: HttpMethods.GET,
        }
      ),
    {
      getNextPageParam: (lastPage, pages) => {
        if (!isSuccessResponse(lastPage)) {
          return pages.length;
        }
        if (
          lastPage.list.length === itemsPerPage &&
          pages.length * itemsPerPage < lastPage.totalItems
        ) {
          return pages.length + 1;
        }
        return undefined;
      },
      suspense: false,
    }
  );
};

export interface IUserResponse {
  data: Pick<
    User,
    'id' | 'cardNumber' | 'email' | 'name' | 'role' | 'banned'
  > & {
    password: string;
  };
}

const useGetUser = (id: number) => {
  const client = useClient();

  return useQuery(
    [ApiCache.USER, { id }],
    () =>
      client<IUserResponse>(`${ApiRoutes.GET_USER}/${id}`, {
        method: HttpMethods.GET,
      }),
    { suspense: false }
  );
};

export type IUseCreateUserRequest = Pick<
  User,
  'cardNumber' | 'email' | 'name' | 'role' | 'banned'
> & {
  password: string;
};

const useCreateUser = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    (request: IUseCreateUserRequest) =>
      client<never, IUseCreateUserRequest>(ApiRoutes.CREATE_USER, {
        data: request,
        method: HttpMethods.POST,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries([ApiCache.USERS]),
    }
  );
};

export interface IUseUpdateUserRequest {
  id: number;
  cardType?: CardType;
  cardNumber?: string | null;
  email: string;
  name: string;
  role: Roles;
}

const useUpdateUser = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    (request: IUseUpdateUserRequest) =>
      client<never, IUseUpdateUserRequest>(ApiRoutes.UPDATE_USER, {
        data: request,
        method: HttpMethods.PATCH,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries([ApiCache.USERS]),
    }
  );
};

export { useGetUsersInfinite, useGetUser, useCreateUser, useUpdateUser };
