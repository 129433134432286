import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonHTMLAttributes } from 'react';
import { createMaps } from '../../../utils/helpers';

const BASE_MARGIN = 'my-3 mx-0 ';
const NO_GUTTER = 'm0 ';

const variantMaps = createMaps({
  primary:
    'bg-accent-default border-accent-default text-white disabled:bg-accent-default hover:bg-accent-dark  ',
  secondary:
    'bg-secondary-default border-secondary-default disabled:bg-secondary-default text-white hover:bg-secondary-dark ',
  danger:
    'bg-red-800 border-red-800 disabled:bg-red-800 text-white hover:bg-red-900 ',
  info: 'bg-gray-500 border-gray-500 disabled:bg-grey-500 text-white hover:bg-grey-600 ',
});

export interface IButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  loading?: boolean;
  className?: string;
  disabled?: boolean;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  fullWidth?: boolean;
  variant?: keyof typeof variantMaps;
  nogutter?: boolean;
}

const Button = ({
  children,
  onClick,
  className,
  disabled,
  loading,
  fullWidth,
  variant = 'primary',
  type = 'button',
  nogutter,
}: IButtonProps) => {
  return (
    <button
      className={
        'border py-1 px-2 disabled:bg-opacity-70 disabled:cursor-not-allowed rounded ' +
        variantMaps[variant] +
        (fullWidth ? 'w-full ' : '') +
        (nogutter ? NO_GUTTER : BASE_MARGIN) +
        (className ? className : '')
      }
      onClick={onClick}
      disabled={disabled || loading}
      type={type}
    >
      {loading ? <FontAwesomeIcon spin icon={faCircleNotch} /> : null}
      {children}
    </button>
  );
};

export default Button;
