import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  client,
  IHttpRequestConfig,
  isSuccessResponse,
} from '../api/apiClient';
import { useGetAccessToken, useGetUser } from '../api/authentication';
import { AppRoutes } from '../constants/routes';
import { CardType, Roles } from '../constants/user';
import { assertValue } from '../utils/helpers';
import { decodeToken } from '../utils/jwt';
import {
  getItemFromStorage,
  LocalStorageKeys,
  removeItemFromStorage,
  setItemToStorage,
} from '../utils/localStorage';
import { IContextProvider } from './appProviders';
import { useSession } from './sessionContext';

export interface User {
  id: number;
  name: string;
  email: string;
  role: Roles;
  cardNumber?: string | null;
  cardType: CardType;
  createdAt: string;
  modifiedAt: string;
  banned?: boolean;
  password?: string;
}

interface LoginProps {
  userData?: User;
  jwtToken: string;
  refreshToken: string;
}
type Login = (data: LoginProps) => void;

export interface IAuthContext {
  user: User | null;
  login: Login;
  logout: () => void;
  refetchUser: () => void;
}

const AuthContext = React.createContext<IAuthContext | undefined>(undefined);
AuthContext.displayName = 'AuthContext';

const AuthProvider = (props: IContextProvider) => {
  const { token, setToken } = useSession();
  const [user, setUser] = useState<User | null>(null);
  const getAccessTokenMutation = useGetAccessToken();
  const queryClient = useQueryClient();

  const logout = useCallback(() => {
    removeItemFromStorage(LocalStorageKeys.REFRESH_TOKEN);
    queryClient.removeQueries();
    setToken(null);
    if (user !== null) {
      setUser(null);
    } else {
      window.location.replace(AppRoutes.LOGIN);
    }
  }, [user]);

  useEffect(() => {
    const refreshToken = getItemFromStorage<string>(
      LocalStorageKeys.REFRESH_TOKEN
    );
    if (!token && refreshToken) {
      getAccessTokenMutation.mutate(refreshToken, {
        onSuccess: (response) => {
          if (isSuccessResponse(response)) {
            setToken(response.data.access);
          } else {
            logout();
          }
        },
      });
    }
  }, []);

  const { data: userResponse, refetch } = useGetUser(token, false, logout);

  useEffect(() => {
    if (token) {
      const decoded = decodeToken(token);
      assertValue(decoded);
    }
  }, [token]);

  useEffect(() => {
    if (token && !user) refetch();
  }, [refetch, token, user]);

  useEffect(() => {
    if (userResponse && isSuccessResponse(userResponse)) {
      setUser(userResponse.data);
    }
  }, [userResponse]);

  const login: Login = useCallback(({ userData, jwtToken, refreshToken }) => {
    setItemToStorage(LocalStorageKeys.REFRESH_TOKEN, refreshToken);
    if (userData) setUser(userData);
    setToken(jwtToken);
  }, []);

  const values = useMemo(
    () => ({ user, login, logout, refetchUser: refetch }),
    [user, login, logout, refetch]
  );

  return <AuthContext.Provider value={values} {...props} />;
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

const useClient = () => {
  const { logout } = useAuth();
  const { token, setToken } = useSession();

  return useCallback(
    <R, T = undefined>(
      endpoint: string,
      requestConfig: IHttpRequestConfig<T>
    ) =>
      client<R, T>(
        endpoint,
        {
          ...requestConfig,
          token: token ?? undefined,
        },
        setToken,
        logout
      ),
    [token]
  );
};

export { AuthProvider, useAuth, useClient };
