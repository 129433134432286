import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AbilityActions, Subjects } from '../../../../config/ability';
import { Can } from '../../../../context/abilityContext';
import Button from '../../Button/Button';

export interface HeaderButtonProps {
  onClick: () => void;
  allowedTo: AbilityActions;
  allowedOn: Subjects;
  icon?: IconDefinition;
  label: string;
  disabled?: boolean;
}

const HeaderButton = ({
  onClick,
  allowedTo,
  allowedOn,
  icon,
  label,
  disabled,
}: HeaderButtonProps) => {
  return (
    <Can do={allowedTo} on={allowedOn}>
      <Button
        nogutter
        variant="secondary"
        onClick={onClick}
        disabled={disabled}
      >
        {icon ? <FontAwesomeIcon icon={icon} className="mr-2" /> : null}
        <span>{label}</span>
      </Button>
    </Can>
  );
};

export default HeaderButton;
