import React from 'react';
import PageHelp from './components/PageHelp';

export interface PageContainerProps {
  children: React.ReactElement | React.ReactElement[];
  title: string;
}

const PageContainer = ({ children, title }: PageContainerProps) => {
  return (
    <div className="w-full h-full overflow-auto flex flex-col">
      <div className="flex justify-between">
        <h1 className="text-3xl font-bold antialiased">{title}</h1>
        <PageHelp />
      </div>
      <hr className="my-splitLine" />
      {children}
    </div>
  );
};

export default PageContainer;
