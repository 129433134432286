import { useMutation, useQuery, useQueryClient } from 'react-query';
import qs from 'qs';
import { ApiCache } from '../constants/cache';
import { ApiRoutes } from '../constants/routes';
import { useClient } from '../context/authContext';
import { IMessage } from '../types/messages';
import { ISortOptions } from '../types/table';
import { getQueryWithParams } from '../utils/helpers';
import { HttpMethods } from './apiClient';

const useUploadMessages = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    (file: File) => {
      const formData = new FormData();
      formData.append('file', file);

      return client(ApiRoutes.UPLOAD_MESSAGES, {
        data: formData,
        method: HttpMethods.POST,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ApiCache.MESSAGES]);
      },
    }
  );
};

export interface IUseGetMessagesRequest {
  sort: Partial<ISortOptions<IMessage>>;
}

export interface IUseGetMessagesResponse {
  list: IMessage[];
}

const useGetMessages = ({ sort }: IUseGetMessagesRequest) => {
  const sortQuery = getQueryWithParams(sort);
  const params = qs.stringify(sortQuery);
  const client = useClient();

  return useQuery(
    [ApiCache.MESSAGES, sort],
    () =>
      client<IUseGetMessagesResponse>(`${ApiRoutes.GET_MESSAGES}?${params}`, {
        method: HttpMethods.GET,
      }),
    { suspense: false }
  );
};

export { useGetMessages, useUploadMessages };
