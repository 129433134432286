import {
  faClipboardList,
  faPen,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AbilityActions, Subjects } from '../../../../config/ability';
import { Can } from '../../../../context/abilityContext';
import Button from '../../Button/Button';

export interface ActionButtonProps {
  callBack: (arg?: unknown) => void;
  allowedTo: AbilityActions;
  allowedOn: Subjects;
}

export interface ActionButtonGroupProps {
  edit?: ActionButtonProps;
  remove?: ActionButtonProps;
  details?: ActionButtonProps;
}

const ActionButtonGroup = ({
  edit,
  remove,
  details,
}: ActionButtonGroupProps) => {
  return (
    <div className="h-9">
      {details ? (
        <Can do={details.allowedTo} on={details.allowedOn}>
          <Button
            variant="info"
            nogutter
            onClick={details.callBack}
            className="rounded-none first:rounded-l last:rounded-r border-r border-white last:border-0"
          >
            <FontAwesomeIcon icon={faClipboardList} />
          </Button>
        </Can>
      ) : null}
      {edit ? (
        <Can do={edit.allowedTo} on={edit.allowedOn}>
          <Button
            onClick={edit.callBack}
            nogutter
            className="rounded-none first:rounded-l last:rounded-r border-r border-white last:border-0"
          >
            <FontAwesomeIcon icon={faPen} />
          </Button>
        </Can>
      ) : null}
      {remove ? (
        <Can do={remove.allowedTo} on={remove.allowedOn}>
          <Button
            variant="danger"
            nogutter
            onClick={remove.callBack}
            className="rounded-none first:rounded-l last:rounded-r border-r border-white last:border-0"
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Can>
      ) : null}
    </div>
  );
};

export default ActionButtonGroup;
