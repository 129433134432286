import * as Yup from 'yup';

/** make a property optional on an interface  */
export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

/** assert type guard to check that value is not null. Throws exception if it is. */
export function assertNumber(value: unknown): asserts value is number {
  if (typeof value === 'number') return;
  else throw new Error(`Value is not number: ${value}`);
}
/** assert type guard to check that value is not null. Throws exception if it is. */
export function assertNotNull<T>(value: T): asserts value is Exclude<T, null> {
  if (value !== null) return;
  else throw new Error(`Value is null: ${value}`);
}
/** assert type guard to check that value is not undefined. Throws exception if it is. */
export function assertDefined<T>(
  value: T
): asserts value is Exclude<T, undefined> {
  if (value !== undefined) return;
  else throw new Error(`Value is undefined: ${value}`);
}
/** assert type guard to check that value is neither undefined nor null. Throws exception if it is either. */
export function assertValue<T>(
  value: T
): asserts value is Exclude<T, null | undefined> {
  if (value !== null && value !== undefined) return;
  else throw new Error(`Value is null or undefined: ${value}`);
}

export function oneOfEnum<T>(enumObject: { [s: string]: T } | ArrayLike<T>) {
  return Yup.mixed<T>().oneOf(Object.values(enumObject));
}

/** formats DateString to human readable string */
export function formatDate(
  date: string | Date | unknown,
  options?: Intl.DateTimeFormatOptions
) {
  if (typeof date !== 'string' && !(date instanceof Date)) return '';
  return new Intl.DateTimeFormat('hu-HU', {
    dateStyle: 'medium',
    timeStyle: 'short',
    ...options,
  }).format(new Date(date));
}

/**
 * Recursively removes empty-string and undefined values from object (NULL and 0 are NOT removed by default)
 * @param formObject object to process
 * @param removeNulls if true removes NULL and 0 values as well
 * @returns formObject cleaned from undefined and empty string values (and NULL or 0 if removeNulls is true)
 */
export const getQueryWithParams = <T extends Record<string, unknown>>(
  formObject: T,
  removeNulls?: boolean
) => {
  const query: Partial<T> = {};

  for (const key in formObject) {
    const filterValue = formObject[key];
    const isObject =
      typeof filterValue === 'object' &&
      filterValue !== null &&
      !Array.isArray(filterValue);

    if (isObject) {
      if (!query[key]) (query[key] as unknown) = {};
      (query[key] as Record<string, unknown>) = getQueryWithParams(
        filterValue as Record<string, unknown>
      );
    } else if (
      !isObject &&
      formObject[key] !== '' &&
      formObject[key] !== 'undefined' &&
      formObject[key] !== undefined &&
      (removeNulls ? formObject[key] !== null : true)
    ) {
      if (!query[key]) (query[key] as unknown) = {};
      query[key] = formObject[key];
    } else {
      delete query[key];
    }
  }

  return query;
};

/** CIF (Constraint Identity Function) helper */
export const createMaps = <ObjectMapType extends Record<string, string>>(
  obj: ObjectMapType
) => obj;
