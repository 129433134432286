import { useEffect, useState } from 'react';
import { useDownloadImage } from '../../../../api/tiles';
import { ITileProps } from './Tile';

const useTile = (imageInfo: ITileProps['imageInfo']) => {
  const [src, setSrc] = useState<string>('');
  const { data, refetch } = useDownloadImage(imageInfo.id);

  useEffect(() => {
    if (data) {
      setSrc(URL.createObjectURL(new Blob([data as Blob])));
    } else {
      setSrc('');
    }
  }, [data]);

  useEffect(() => {
    if (imageInfo.id) {
      refetch();
    }
  }, [imageInfo]);

  return {
    src,
    fileName: imageInfo.fileName,
  };
};

export default useTile;
