import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import qs from 'qs';
import { Column, useAsyncDebounce } from 'react-table';
import { isSuccessResponse } from '../../../api/apiClient';
import {
  useDownloadPrizes,
  useGetPrizes,
  useUploadPrizes,
} from '../../../api/prizes';
import {
  getOptionLabel,
  PRIZE_STATUS_OPTIONS,
} from '../../../constants/options';
import useNotifications from '../../../hooks/useNotifications';
import { IPrize } from '../../../types/prizes';
import { ISortOptions, Order } from '../../../types/table';
import {
  assertValue,
  formatDate,
  getQueryWithParams,
} from '../../../utils/helpers';
import SortableHeader from '../../ui/Table/components/SortableHeader';

export interface IPrizesFilter {
  dateFrom?: Date;
}

export type FileUploadHandler = (
  uploadFile: File | undefined,
  onSuccess?: () => void
) => void;

const usePrizes = () => {
  const [filters, setFilters] = useState<IPrizesFilter>({});
  const [sort, setSort] = useState<ISortOptions<IPrize>>({
    order: 'DESC',
    orderBy: 'uploadDate',
  });
  const { data, isLoading, isFetching } = useGetPrizes({ filters, sort });
  const notifications = useNotifications();
  const uploadPrizesMutation = useUploadPrizes();
  const downloadPrizesMutation = useDownloadPrizes();

  const toggleSort = (column: keyof IPrize) => {
    setSort((prevSort) => {
      let order: Order = 'DESC';
      if (prevSort.orderBy === column) {
        order = prevSort.order === 'ASC' ? 'DESC' : 'ASC';
      }
      return {
        orderBy: column,
        order,
      };
    });
  };

  const handleSetFilters = useAsyncDebounce((values: { dateFrom?: Date }) => {
    setFilters({ ...values });
  }, 500);

  const handleFileUpload: FileUploadHandler = (uploadFile, onSuccess) => {
    assertValue(uploadFile);
    uploadPrizesMutation.mutate(uploadFile, {
      onSuccess: (response) => {
        if (!isSuccessResponse(response)) {
          notifications.handleExcelErrorResponse(response, {
            0: 'Törzsvásárlói azonosító',
            1: 'Üzenet szövege',
            2: 'Nyeremény kód',
          });
        }
        if (onSuccess) onSuccess();
      },
    });
  };

  const handleFileDownload = () => {
    const parsed = {
      dateFrom: filters.dateFrom
        ? format(filters.dateFrom, 'yyyy-MM-dd')
        : filters.dateFrom,
    };
    const filterQuery = getQueryWithParams(parsed, true);
    const params = qs.stringify(filterQuery);
    downloadPrizesMutation.mutate(
      { params },
      {
        onSuccess: (response) => {
          if (response) {
            const today = format(new Date(), 'yyyy-MM-dd-HH-mm-ss');
            const url = window.URL.createObjectURL(
              new Blob([response as Blob])
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `nyeremenyek_export_${today}.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
        },
      }
    );
  };

  const tableData = data && isSuccessResponse(data) ? data.list : [];

  const columns = useMemo<Column<IPrize>[]>(
    () => [
      {
        accessor: 'cardNumber',
        Header: (headerProps) => (
          <SortableHeader
            currentSortId={sort.orderBy}
            currentSortOrder={sort.order}
            id={headerProps.column.id}
            label="Törzsvásárlói azonosító"
            handleClick={() =>
              toggleSort(headerProps.column.id as keyof IPrize)
            }
          />
        ),
      },
      {
        accessor: 'email',
        Header: (headerProps) => (
          <SortableHeader
            currentSortId={sort.orderBy}
            currentSortOrder={sort.order}
            id={headerProps.column.id}
            label="E-mail cím"
            handleClick={() =>
              toggleSort(headerProps.column.id as keyof IPrize)
            }
          />
        ),
      },
      {
        accessor: 'uploadDate',
        Header: (headerProps) => (
          <SortableHeader
            currentSortId={sort.orderBy}
            currentSortOrder={sort.order}
            id={headerProps.column.id}
            label="Feltöltés dátuma"
            handleClick={() =>
              toggleSort(headerProps.column.id as keyof IPrize)
            }
          />
        ),
        Cell: ({ value }) => formatDate(value, { timeStyle: undefined }),
      },
      {
        accessor: 'message',
        Header: (headerProps) => (
          <SortableHeader
            currentSortId={sort.orderBy}
            currentSortOrder={sort.order}
            id={headerProps.column.id}
            label="Üzenet szövege"
            handleClick={() =>
              toggleSort(headerProps.column.id as keyof IPrize)
            }
          />
        ),
      },
      {
        accessor: 'code',
        Header: (headerProps) => (
          <SortableHeader
            currentSortId={sort.orderBy}
            currentSortOrder={sort.order}
            id={headerProps.column.id}
            label="Azonosító"
            handleClick={() =>
              toggleSort(headerProps.column.id as keyof IPrize)
            }
          />
        ),
      },
      {
        accessor: 'status',
        Header: (headerProps) => (
          <SortableHeader
            currentSortId={sort.orderBy}
            currentSortOrder={sort.order}
            id={headerProps.column.id}
            label="Státusz"
            handleClick={() =>
              toggleSort(headerProps.column.id as keyof IPrize)
            }
          />
        ),
        Cell: ({ value }) => getOptionLabel(value, PRIZE_STATUS_OPTIONS),
      },
    ],
    [tableData, sort]
  );

  const totalCount: number =
    data && isSuccessResponse(data) ? data.list.length : 0;

  return {
    handleFileUpload,
    handleFileDownload,
    data: tableData,
    columns,
    isLoading: isLoading || isFetching,
    totalCount: totalCount,
    setFilters: handleSetFilters,
  };
};

export default usePrizes;
