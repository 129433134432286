import { useMemo, useRef, useState } from 'react';
import { Column, useAsyncDebounce } from 'react-table';
import { isSuccessResponse } from '../../../api/apiClient';
import { useGetUsersInfinite } from '../../../api/users';
import { User } from '../../../context/authContext';
import { ISortOptions, Order } from '../../../types/table';
import { formatDate } from '../../../utils/helpers';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import SortableHeader from '../../ui/Table/components/SortableHeader';
import HeaderButton from '../../ui/Table/components/HeaderButton';
import { AbilityActions } from '../../../config/ability';
import ActionButtonGroup from '../../ui/Table/components/ActionButtonGroup';
import { subject } from '@casl/ability';
import { createUserAbilitySubject } from '../../../config/abilitySubjectTypes';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useModal } from '../../../context/modalContext';
import AddUserForm from './components/AddUserForm';
import UpdateUserForm from './components/UpdateUserForm';
import { getOptionLabel, ROLES_OPTIONS } from '../../../constants/options';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalOverlay from '../../ui/Overlays/ModalOverlay/ModalOverlay';

const useUsers = () => {
  const [filters, setFilters] = useState<Partial<User>>({});
  const { openModalWithContent } = useModal();
  const [sort, setSort] = useState<ISortOptions<User>>({
    orderBy: 'createdAt',
    order: 'DESC',
  });

  const toggleSort = (column: keyof User) => {
    setSort((prevSort) => {
      let order: Order = 'DESC';
      if (prevSort.orderBy === column) {
        order = prevSort.order === 'ASC' ? 'DESC' : 'ASC';
      }
      return {
        orderBy: column,
        order,
      };
    });
  };

  const handleSetFilters = useAsyncDebounce((values: Partial<User>) => {
    setFilters({ ...values });
  }, 500);

  const { data, fetchNextPage, hasNextPage, isLoading, isFetching } =
    useGetUsersInfinite({
      filters,
      sort,
    });

  const tableData = data
    ? data.pages.flatMap((p) => (isSuccessResponse(p) ? p.list : []))
    : [];

  const columns = useMemo<Column<User & { edit?: never }>[]>(
    () => [
      {
        accessor: 'edit',
        Header: (headerProps) => (
          <HeaderButton
            onClick={() =>
              openModalWithContent(
                <ModalOverlay>
                  <AddUserForm />
                </ModalOverlay>
              )
            }
            allowedTo={AbilityActions.CREATE}
            allowedOn={'User'}
            icon={faPlus}
            label="Felvétel"
          />
        ),
        Cell: (rowProps) => (
          <ActionButtonGroup
            edit={{
              callBack: () =>
                openModalWithContent(
                  <ModalOverlay>
                    <UpdateUserForm userId={rowProps.row.original.id} />
                  </ModalOverlay>
                ),
              allowedOn: subject(
                'User',
                createUserAbilitySubject(rowProps.row.original)
              ),
              allowedTo: AbilityActions.UPDATE,
            }}
          />
        ),
      },
      {
        accessor: 'role',
        Header: (headerProps) => (
          <SortableHeader
            currentSortId={sort.orderBy}
            currentSortOrder={sort.order}
            id={headerProps.column.id}
            label="Jogosultság"
            handleClick={() => toggleSort(headerProps.column.id as keyof User)}
          />
        ),
        Cell: ({ value }) => getOptionLabel(value, ROLES_OPTIONS),
      },
      {
        accessor: 'email',
        Header: (headerProps) => (
          <SortableHeader
            currentSortId={sort.orderBy}
            currentSortOrder={sort.order}
            id={headerProps.column.id}
            label="E-mail cím"
            handleClick={() => toggleSort(headerProps.column.id as keyof User)}
          />
        ),
      },
      {
        accessor: 'name',
        Header: (headerProps) => (
          <SortableHeader
            currentSortId={sort.orderBy}
            currentSortOrder={sort.order}
            id={headerProps.column.id}
            label="Név"
            handleClick={() => toggleSort(headerProps.column.id as keyof User)}
          />
        ),
      },
      {
        accessor: 'cardNumber',
        Header: (headerProps) => (
          <SortableHeader
            currentSortId={sort.orderBy}
            currentSortOrder={sort.order}
            id={headerProps.column.id}
            label="Törzsvásárlói kátya száma"
            handleClick={() => toggleSort(headerProps.column.id as keyof User)}
          />
        ),
      },
      {
        accessor: 'createdAt',
        Header: (headerProps) => (
          <SortableHeader
            currentSortId={sort.orderBy}
            currentSortOrder={sort.order}
            id={headerProps.column.id}
            label="Létrehozva"
            handleClick={() => toggleSort(headerProps.column.id as keyof User)}
          />
        ),
        Cell: ({ value }) => formatDate(value),
      },
      {
        accessor: 'banned',
        Header: (headerProps) => (
          <SortableHeader
            currentSortId={sort.orderBy}
            currentSortOrder={sort.order}
            id={headerProps.column.id}
            label="Tiltott"
            handleClick={() => toggleSort(headerProps.column.id as keyof User)}
          />
        ),
        Cell: (rowData) =>
          rowData.value ? (
            <FontAwesomeIcon
              icon={faCheck}
              size="lg"
              className="text-red-600"
            />
          ) : null,
      },
    ],
    [sort]
  );

  const bottomRef = useRef<HTMLTableRowElement>(null);
  const rootRef = useRef<HTMLTableSectionElement>(null);

  useIntersectionObserver({
    root: rootRef,
    target: bottomRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  const lastPage = data && data.pages[data.pages.length - 1];
  const totalCount: number | null =
    lastPage && isSuccessResponse(lastPage) ? lastPage.totalItems : 0;

  return {
    data: tableData,
    columns,
    isLoading: isLoading || isFetching,
    rootRef,
    bottomRef,
    setFilters: handleSetFilters,
    totalCount: totalCount,
  };
};

export default useUsers;
