import { startOfMonth } from 'date-fns';
import { isSuccessResponse } from '../../../api/apiClient';
import {
  useGetAppUsage,
  useGetCollectedPrizes,
  useGetCustomers,
  useGetFreeCards,
  useGetTileViews,
  useGetUsersCount,
} from '../../../api/dashboard';
import { CardVariant } from './components/Card';

interface ICardValues {
  key: string;
  title: string;
  value: number | string;
  isFetching: boolean;
  variant: CardVariant;
}

interface IDashboardData {
  label: string;
  cards: ICardValues[];
}

const useDashboard = (): IDashboardData[] => {
  const currentMonthStart = startOfMonth(new Date());

  const customersQuery = useGetCustomers();
  const freeCardsQuery = useGetFreeCards();
  const newUsersQuery = useGetUsersCount({ dateFrom: currentMonthStart });
  const appUsageQuery = useGetAppUsage({ dateFrom: currentMonthStart });
  const tileViewsQuery = useGetTileViews({ dateFrom: currentMonthStart });
  const collectedPrizesQuery = useGetCollectedPrizes({
    dateFrom: currentMonthStart,
  });

  return [
    {
      label: 'Rendszer információk',
      cards: [
        {
          key: 'usersCount',
          title: 'Törzsvásárlók száma',
          value: isSuccessResponse(customersQuery.data)
            ? customersQuery.data.data
            : '',
          isFetching: customersQuery.isFetching,
          variant: 'green',
        },
        {
          key: 'freeCards',
          title: 'Szabad törzsvásárlói kódok',
          value: isSuccessResponse(freeCardsQuery.data)
            ? freeCardsQuery.data.data
            : '',
          isFetching: freeCardsQuery.isFetching,
          variant: 'red',
        },
      ],
    },
    {
      label: 'Aktuális hónap adatai',
      cards: [
        {
          key: 'newUsers',
          title: 'Új felhasználók',
          value: isSuccessResponse(newUsersQuery.data)
            ? newUsersQuery.data.data
            : '',
          isFetching: newUsersQuery.isFetching,
          variant: 'green',
        },
        {
          key: 'appUsage',
          title: 'Alkalmazás használat',
          value: isSuccessResponse(appUsageQuery.data)
            ? appUsageQuery.data.data
            : '',
          isFetching: appUsageQuery.isFetching,
          variant: 'blue',
        },
        {
          key: 'tileViews',
          title: 'Csempe megtekintések',
          value: isSuccessResponse(tileViewsQuery.data)
            ? tileViewsQuery.data.data
            : '',
          isFetching: tileViewsQuery.isFetching,
          variant: 'yellow',
        },

        {
          key: 'collectedPrizes',
          title: 'Átvett nyeremények',
          value: isSuccessResponse(collectedPrizesQuery.data)
            ? collectedPrizesQuery.data.data
            : '',
          isFetching: collectedPrizesQuery.isFetching,
          variant: 'green',
        },
      ],
    },
  ];
};

export default useDashboard;
