import { useEffect, useState } from 'react';
import { isSuccessResponse } from '../../../api/apiClient';
import { useGetImagesInfo } from '../../../api/tiles';
import { ImageInfo } from '../../../types/tiles';

const IMAGE_COUNT = 4;
const createDefaultState = () => {
  const result: ImageInfo[] = [];
  for (let i = 0; i < IMAGE_COUNT; i++) {
    result.push({ position: i + 1, fileName: 'file_name.jpg' });
  }
  return result;
};

const useTiles = () => {
  const [tiles, setTiles] = useState<ImageInfo[]>(createDefaultState());
  const { data } = useGetImagesInfo();

  useEffect(() => {
    if (data && isSuccessResponse(data)) {
      const newState: typeof tiles = [];
      for (let i = 1; i <= IMAGE_COUNT; i++) {
        const curr = data.list.find((tile) => tile.position === i);
        newState.push(
          curr ?? {
            position: newState.length + 1,
            fileName: 'file_name.jpg',
          }
        );
      }
      setTiles(newState);
    } else {
      setTiles(createDefaultState());
    }
  }, [data]);

  return { tiles };
};

export default useTiles;
