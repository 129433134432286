import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from './components/pages/Dashboard/Dashboard';
import ForgotPassword from './components/pages/ForgotPassword/ForgotPassword';
import Login from './components/pages/Login/Login';
import Messages from './components/pages/Messages/Messages';
import NewPassword from './components/pages/NewPassword/NewPassword';
import Prizes from './components/pages/Prizes/Prizes';
import Tiles from './components/pages/Tiles/Tiles';
import Users from './components/pages/Users/Users';
import { AppRoutes } from './constants/routes';

const UnauthorizedLayout = React.lazy(
  () => import('./components/layout/UnauthorizedLayout/UnauthorizedLayout')
);
const AuthorizedLayout = React.lazy(
  () => import('./components/layout/AuthorizedLayout/AuthorizedLayout')
);

const App = () => {
  return (
    <Routes>
      <Route
        path={AppRoutes.UNAUTHENTICATED_ROOT}
        element={<UnauthorizedLayout />}
      >
        <Route path={AppRoutes.LOGIN} element={<Login />}></Route>
        <Route
          path={AppRoutes.FORGOTTEN_PASSWORD}
          element={<ForgotPassword />}
        ></Route>
        <Route
          path={AppRoutes.CHANGE_PASSWORD}
          element={<NewPassword />}
        ></Route>
        <Route path="*" element={<Navigate to={AppRoutes.LOGIN} />} />
      </Route>
      <Route path={AppRoutes.AUTHENTICATED_ROOT} element={<AuthorizedLayout />}>
        <Route index element={<Dashboard />}></Route>
        <Route path={AppRoutes.TILES} element={<Tiles />} />
        <Route path={AppRoutes.MESSAGES} element={<Messages />} />
        <Route path={AppRoutes.PRIZES} element={<Prizes />} />
        <Route path={AppRoutes.USERS} element={<Users />} />
      </Route>
    </Routes>
  );
};

export default App;
