import { Controller, useFormContext } from 'react-hook-form';
import { IDefaultControlProps, useCustomFormMethods } from '../../Form';
import Control, {
  BASE_INPUT_CLASS,
  INVALID_INPUT_CLASS,
} from '../Control/Control';
import ReactSelect, { components } from 'react-select';

export interface SelectOptions<T> {
  label: string;
  value: T;
  disabled?: boolean;
}

interface ISelectProps<T> extends IDefaultControlProps {
  options: SelectOptions<T>[];
}

const Option = (props: any) => {
  return (
    <components.Option
      {...props}
      className={
        'cursor-pointer ' +
        (props.isSelected
          ? 'bg-primary-default text-white hover:bg-primary-dark'
          : 'text-primary-default hover:bg-primary-light')
      }
    />
  );
};

const Input = (props: any) => (
  <components.Input {...props} className="focus:shadow-inputOutline" />
);

const Select = <T,>(props: ISelectProps<T>) => {
  const { name, label, options, placeholder, disabled } = props;
  const { formState, control, setValue } = useFormContext();
  const { onControlValueChange } = useCustomFormMethods();

  return (
    <Control name={name} label={label} error={formState.errors[name]?.message}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { value, ...restRenderProps } = field;
          const selectValue = options.find((o) => o.value === value) ?? null;
          return (
            <ReactSelect
              {...restRenderProps}
              value={selectValue}
              placeholder={placeholder ?? 'Válassz'}
              isDisabled={disabled}
              options={options}
              components={{ Option, Input }}
              className={
                BASE_INPUT_CLASS +
                (formState.errors[name] ? INVALID_INPUT_CLASS : '')
              }
              onChange={(changeValue, action) => {
                const newValue = changeValue?.value ?? null;
                setValue(name, newValue, {
                  shouldDirty: true,
                  shouldTouch: true,
                  shouldValidate: true,
                });
                if (onControlValueChange) onControlValueChange();
              }}
              isClearable
              styles={{
                control: (base) => ({
                  ...base,
                  background: formState.errors[name]
                    ? 'rgb(254 242 242)'
                    : 'inherit',
                  padding: 0,
                  border: 'none',
                  boxShadow: 'none',
                  margin: '-0.25rem -0.5rem',
                  maxHeight: '32px',
                  minHeight: 'unset',
                  ':hover': {
                    border: 'none',
                    boxShadow: 'none',
                  },
                }),
                valueContainer: (base) => ({
                  ...base,
                  padding: '0 8px',
                }),
                menu: (base) => ({
                  ...base,
                  left: 0,
                  borderRadius: 0,
                }),
                option: (base) => ({
                  padding: '8px 12px',
                }),
              }}
            />
          );
        }}
      />
    </Control>
  );
};

export default Select;
