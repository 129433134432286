import PageContainer from '../../ui/PageContainer/PageContainer';
import Table from '../../ui/Table/Table';
import useUsers from './useUsers';
import Form from '../../ui/Form/Form';
import TextInput from '../../ui/Form/Controls/TextInput/TextInput';
import { User } from '../../../context/authContext';
import Select from '../../ui/Form/Controls/Select/Select';
import { ROLES_OPTIONS } from '../../../constants/options';

const Users = () => {
  const {
    data,
    columns,
    isLoading,
    rootRef,
    bottomRef,
    setFilters,
    totalCount,
  } = useUsers();

  return (
    <PageContainer title="Felhasználók adminisztrációja">
      <div>
        <Form<Partial<User>>
          onSubmit={() => {}}
          onControlValueChange={(values) => setFilters(values)}
        >
          <div className="grid gap-x-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-4 w-">
            <Select label="Jogosultság" name="role" options={ROLES_OPTIONS} />
            <TextInput label="E-mail cím" name="email" />
            <TextInput label="Név" name="name" />
            <TextInput label="Törzsvásárlói kártya" name="cardNumber" />
          </div>
          <hr className="my-splitLine" />
        </Form>
      </div>
      <div className="flex-1 min-h-[400px]">
        <Table
          data={data}
          columns={columns}
          isLoading={isLoading}
          bottomRef={bottomRef}
          rootRef={rootRef}
          getHeaderProps={(headerInfo) => {
            switch (headerInfo.id) {
              case 'role':
                return { className: 'w-24' };
              case 'cardNumber':
                return { className: 'w-60' };
              case 'createdAt':
                return { className: 'w-52' };
              case 'edit':
                return { className: 'w-24' };
              case 'banned':
                return { className: 'w-12' };
              default:
                return {};
            }
          }}
        />
      </div>
      <div>Elemek: {totalCount}</div>
    </PageContainer>
  );
};

export default Users;
