import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Loader = () => {
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 flex h-full justify-center items-center space-x-2 text-secondary-default">
      <FontAwesomeIcon spin icon={faCircleNotch} size="8x" />
    </div>
  );
};

export default Loader;
