import { SelectOptions } from '../components/ui/Form/Controls/Select/Select';
import { MessageStatus } from '../types/messages';
import { PrizeStatus } from '../types/prizes';
import { CardType, Roles } from './user';

export const ROLES_OPTIONS: SelectOptions<Roles>[] = [
  { label: 'Felhasználó', value: Roles.USER },
  { label: 'Szerkesztő', value: Roles.EDITOR },
  { label: 'Admin', value: Roles.ADMIN },
];

export const CARD_TYPE_OPTIONS: SelectOptions<CardType>[] = [
  { label: 'Nem kell', value: CardType.NOT_NEEDED },
  { label: 'Generált', value: CardType.GENERATED },
  { label: 'Egyedi', value: CardType.CUSTOM },
];

export const MESSAGE_STATUS_OPTIONS: SelectOptions<MessageStatus>[] = [
  { label: 'Olvasott', value: MessageStatus.READ },
  { label: 'Nem olvasott', value: MessageStatus.UNREAD },
];

export const PRIZE_STATUS_OPTIONS: SelectOptions<PrizeStatus>[] = [
  { label: 'Átvett', value: PrizeStatus.COLLECTED },
  { label: 'Nem átvett', value: PrizeStatus.WAITING },
  { label: 'Lejárt', value: PrizeStatus.EXPIRED },
];

export const getOptionLabel = <T>(
  o: T,
  selectOptions: SelectOptions<T>[]
): string => {
  return selectOptions.find((option) => option.value === o)?.label ?? '' + o;
};
