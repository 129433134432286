import { useClient } from '../context/authContext';
import { getQueryWithParams } from '../utils/helpers';
import qs from 'qs';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ApiCache } from '../constants/cache';
import { ApiRoutes } from '../constants/routes';
import { HttpMethods } from './apiClient';
import { IPrize } from '../types/prizes';
import { IPrizesFilter } from '../components/pages/Prizes/usePrizes';
import { format } from 'date-fns';
import { ISortOptions } from '../types/table';

export interface IUseGetPrizesRequest {
  filters: IPrizesFilter;
  sort: Partial<ISortOptions<IPrize>>;
}

export interface IUseGetPrizesResponse {
  list: IPrize[];
}

const useGetPrizes = ({ filters, sort }: IUseGetPrizesRequest) => {
  const parsed = {
    dateFrom: filters.dateFrom
      ? format(filters.dateFrom, 'yyyy-MM-dd')
      : filters.dateFrom,
  };
  const client = useClient();
  const filterQuery = getQueryWithParams(parsed, true);
  const sortQuery = getQueryWithParams(sort);
  const params = qs.stringify(filterQuery) + '&' + qs.stringify(sortQuery);

  return useQuery(
    [ApiCache.PRIZES, filters, sort],
    () =>
      client<IUseGetPrizesResponse>(
        `${ApiRoutes.GET_PRIZES}${params ? '?' + params : ''}`,
        {
          method: HttpMethods.GET,
        }
      ),
    { suspense: false }
  );
};

const useUploadPrizes = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    (file: File) => {
      const formData = new FormData();
      formData.append('file', file);

      return client(ApiRoutes.UPLOAD_PRIZES, {
        data: formData,
        method: HttpMethods.POST,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ApiCache.PRIZES]);
      },
    }
  );
};

const useDownloadPrizes = () => {
  const client = useClient();

  return useMutation(({ params }: { params?: string }) =>
    client<Blob>(`${ApiRoutes.DOWNLOAD_PRIZES}${params ? '?' + params : ''}`, {
      method: HttpMethods.GET,
      responseType: 'blob',
    })
  );
};

export { useGetPrizes, useUploadPrizes, useDownloadPrizes };
