import { useToasts } from 'react-toast-notifications';
import { IErrorResponse } from '../api/apiClient';
import { Notifications } from '../constants/notifications';

export const hasNotificationMessage = (
  message: string
): message is keyof typeof Notifications => {
  return (
    Object.keys(Notifications).indexOf(
      message as keyof typeof Notifications
    ) !== -1
  );
};

export interface IExcelErrorResponse extends IErrorResponse {
  excelErrors: { row: number; col: number; error: Notifications | string }[];
}

export const isExcelErrorResponse = (
  response: IErrorResponse
): response is IExcelErrorResponse => {
  return response.messages.includes('EXCEL');
};

const useNotifications = () => {
  const toasts = useToasts();

  const success = (message: string) => {
    return toasts.addToast(message, { appearance: 'success' });
  };
  const error = (message: string) => {
    return toasts.addToast(message, { appearance: 'error' });
  };
  const info = (message: string) => {
    return toasts.addToast(message, { appearance: 'info' });
  };
  const warning = (message: string) => {
    return toasts.addToast(message, { appearance: 'warning' });
  };

  const handleErrorResponse = (response: IErrorResponse) => {
    response.messages
      .map((message) => {
        const code = message.split(':')[0]?.trim();
        const value = message.split(':')[1]?.trim();
        if (hasNotificationMessage(code)) {
          return error(`${Notifications[code]}${value ? ': ' + value : ''}`);
        }
        return error(message);
      })
      .join(',');
  };

  const handleExcelErrorResponse = (
    response: IErrorResponse,
    columnMap: Record<number, string>
  ) => {
    if (isExcelErrorResponse(response)) {
      response.excelErrors.map((err) => {
        return error(
          `A(z) ${err.row}. sorban a(z) ${columnMap[err.col]} ${
            hasNotificationMessage(err.error)
              ? Notifications[err.error]
              : err.error
          }`
        );
      });
    } else {
      handleErrorResponse(response);
    }
  };

  return {
    success,
    error,
    info,
    warning,
    handleErrorResponse,
    handleExcelErrorResponse,
  };
};

export default useNotifications;
