export enum AppRoutes {
  UNAUTHENTICATED_ROOT = '/auth',
  LOGIN = '/auth/login',
  FORGOTTEN_PASSWORD = '/auth/forgotten-password',
  CHANGE_PASSWORD = '/auth/change-password',

  AUTHENTICATED_ROOT = '/',
  TILES = '/tiles',
  MESSAGES = '/messages',
  PRIZES = '/prizes',
  USERS = '/users',
}

export enum ApiRoutes {
  LOGIN = '/account/login',
  FORGOTTEN_PASSWORD = '/account/lost-pass',
  GET_ACCESS_TOKEN = '/s/user/get-access',
  GET_CURRENT_USER = '/s/user/get',
  NEW_PASSWORD = '/account/ch-pass',

  IMAGES_INFO = '/s/images/info',
  DOWNLOAD_IMAGE = '/s/images/download',
  DELETE_IMAGE = '/s/images/delete',
  UPLOAD_IMAGE = '/s/images/upload',

  GET_USERS = '/s/admin/users/list',
  GET_USER = '/s/admin/users/get',
  CREATE_USER = '/s/admin/users/create',
  UPDATE_USER = '/s/admin/users/update',

  GET_MESSAGES = '/s/messages/list',
  UPLOAD_MESSAGES = '/s/messages/upload',

  GET_PRIZES = '/s/prizes/list',
  UPLOAD_PRIZES = '/s/prizes/upload',
  DOWNLOAD_PRIZES = '/s/prizes/export',

  DASHBOARD_FREE_CARDS = '/s/dashboard/free-cards',
  DASHBOARD_USERS = '/s/dashboard/users',
  DASHBOARD_APP_USAGE = '/s/dashboard/app-usage',
  DASHBOARD_TILE_VIEWS = '/s/dashboard/tile-views',
  DASHBOARD_CUSTOMERS = '/s/dashboard/customers',
  DASHBOARD_COLLECTED_PRIZES = '/s/dashboard/collected-prizes',
}

export const ROUTE_HELP_HTML_MAPPINGS: { [key in AppRoutes]?: string } = {
  [AppRoutes.AUTHENTICATED_ROOT]: 'dashboard',
  [AppRoutes.TILES]: 'csempek',
  [AppRoutes.MESSAGES]: 'uzenetek',
  [AppRoutes.PRIZES]: 'nyeremenyek',
  [AppRoutes.USERS]: 'felhasznalok',
};
