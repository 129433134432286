export enum ApiCache {
  USER = 'user',
  USERS = 'users',
  IMAGES_INFO = 'images_info',
  IMAGE = 'image',
  MESSAGES = 'messages',
  PRIZES = 'prizes',
  DOWNLOAD_PRIZES = 'download_prizes',
  FREE_CARDS = 'free_cards',
  CUSTOMERS = 'customers',
  USERS_COUNT = 'users_count',
  APP_USAGE = 'app_usage',
  TILE_VIEWS = 'tile_views',
  COLLECTED_PRIZES = 'collected_prizes',
}
