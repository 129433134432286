import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AppRoutes,
  ROUTE_HELP_HTML_MAPPINGS,
} from '../../../../constants/routes';
import { useModal } from '../../../../context/modalContext';
import useNotifications from '../../../../hooks/useNotifications';
import PageHelpOverlay from '../../Overlays/PageHelpOverlay/PageHelpOverlay';

const HELP_HTML_BASE_ROUTE = window.origin + '/upload/help';

const PageHelp = () => {
  const location = useLocation();
  const [content, setContent] = useState<string>();
  const { openModalWithContent } = useModal();
  const notifications = useNotifications();

  useEffect(() => {
    fetch(
      `${HELP_HTML_BASE_ROUTE}/${
        ROUTE_HELP_HTML_MAPPINGS[location.pathname as AppRoutes]
      }.html`
    )
      .then((response) => response.text())
      .then((response) => {
        setContent(response);
      })
      .catch((err) => {
        setContent('');
        if (typeof err === 'string') {
          notifications.error(err);
        } else {
          notifications.error(JSON.stringify(err));
        }
      });
  }, []);

  if (!content) return null;

  return (
    <button
      onClick={() =>
        openModalWithContent(
          <PageHelpOverlay>
            <div dangerouslySetInnerHTML={{ __html: content ?? '' }} />
          </PageHelpOverlay>
        )
      }
    >
      <FontAwesomeIcon icon={faQuestionCircle} size="2x" />
    </button>
  );
};

export default PageHelp;
