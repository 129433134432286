import PageContainer from '../../ui/PageContainer/PageContainer';
import Tile from './components/Tile';
import useTiles from './useTiles';

const Tiles = () => {
  const { tiles } = useTiles();

  return (
    <PageContainer title="Csempék kezelése">
      <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 sm:grid-rows-2 flex-1 overflow-auto max-w-5xl mx-auto">
        {tiles.map((tile) => (
          <Tile key={tile.position} imageInfo={tile} />
        ))}
      </div>
    </PageContainer>
  );
};
export default Tiles;
