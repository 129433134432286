import ReactDatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { IDefaultControlProps, useCustomFormMethods } from '../../Form';
import Control, {
  BASE_INPUT_CLASS,
  INVALID_INPUT_CLASS,
} from '../Control/Control';
import hu from 'date-fns/locale/hu';
import 'react-datepicker/dist/react-datepicker.css';

interface IDatePickerProps extends IDefaultControlProps {}

const DatePicker = (props: IDatePickerProps) => {
  const { name, label, placeholder, disabled } = props;
  const { control, formState, setValue } = useFormContext();
  const { onControlValueChange } = useCustomFormMethods();

  const handleChange = (date: Date | null) => {
    setValue(name, date, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
    if (onControlValueChange) onControlValueChange();
  };

  return (
    <Control name={name} label={label} error={formState.errors[name]?.message}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { value, ...restRenderProps } = field;
          return (
            <ReactDatePicker
              {...restRenderProps}
              dateFormat={'yyyy. MMMM dd.'}
              value={value}
              selected={value}
              locale={hu}
              onChange={handleChange}
              placeholderText={placeholder}
              disabled={disabled}
              className={
                'w-full ' +
                BASE_INPUT_CLASS +
                (formState.errors[name] ? INVALID_INPUT_CLASS : '')
              }
              wrapperClassName="parola-calendar__wrapper"
              calendarClassName="parola-calendar"
              dropdownMode="select"
              isClearable
              showMonthDropdown
              showYearDropdown
            />
          );
        }}
      />
    </Control>
  );
};

export default DatePicker;
