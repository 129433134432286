export enum Notifications {
  NA = 'Nincs ilyen adat',
  FORBIDDEN = 'Nincs megfelelő jogosultság',
  EXISTS_USER = 'Nem megfelelő felhasználó',
  INVALID_USER = 'Érvénytelen email/jelszó',
  INVALID_ROLE = 'Nem megfelelő szerepkör',
  INVALID_AUTHENTICATION = 'Érvénytelen email/jelszó',
  INVALID_TOKEN = 'Sikertelen azonosítás',
  INVALID_PARAMS = 'Hibás paraméterek',
  INVALID_IMAGE_RATIO = 'Nem megfelelő a kép aránya',
  TOKEN_EXPIRED = 'Lejárt authentikáció',
  TECHNICAL = 'Technikai hiba',
  FORGOT_PASSWORD_SUCCESS = 'A megadott email címre elküldtük a levelet',
  AUTHORIZATION_ERROR = 'Jogosultság hiba',
  USER_CREATED = 'Felhasználó létrehozva',
  USER_UPDATED = 'Felhasználó frissítve',
  DUPLICATED_VALUE = 'A listában nem szerepelhet többször ugyanaz a törzsvásárló',
  INVALID_CARD = 'Hibás törzsvásárló azonosító',
  NOT_FIRST_ROW = 'Nincs fejléc az 1. sorban',
  HEADER = 'Nem jó sorrend, vagy nem jó elnevezés a fejlécben',
  WRONG_COLUMN = 'Az oszlopok sorrendje nem jó ',
  REQUIRED = 'kötelezõ mezõ',
  TYPE_NUMBER = 'nem szám',
  TYPE_DATE = 'nem dátum',
  TYPE_STRING = 'nem szöveg',
  EXISTS_CARD = 'nem kiadható kártyaszám',
}
