import { AbilityBuilder, Ability, AbilityClass } from '@casl/ability';
import { AppRoutes } from '../constants/routes';
import { Roles } from '../constants/user';
import { UserAbilitySubject } from './abilitySubjectTypes';

/**
 * 'manage' is a wildcard for CRUD actions by default - any other custom action won't be included
 */
export enum AbilityActions {
  MANAGE = 'manage',
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
}

export type Actions = AbilityActions;

/**
 * 'all' is a wildcard for all subjects by default
 */
export type Subjects =
  | AppRoutes
  | (UserAbilitySubject | 'User')
  | Roles
  | 'all';

export type AppAbilityType = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbilityType>;

export default function defineRulesFor(role: Roles | undefined) {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility);

  switch (role) {
    case Roles.ADMIN:
      can(AbilityActions.MANAGE, 'all');
      break;
    case Roles.EDITOR:
      can(AbilityActions.MANAGE, 'all');
      cannot(AbilityActions.MANAGE, Roles.ADMIN);
      cannot(AbilityActions.MANAGE, Roles.EDITOR);
      cannot(AbilityActions.MANAGE, 'User');
      can(AbilityActions.CREATE, 'User', { role: Roles.USER });
      can(AbilityActions.UPDATE, 'User', { role: Roles.USER });
      break;
    default:
      return;
  }

  return rules;
}

export function buildAbilityFor(role: Roles | undefined): AppAbilityType {
  return new AppAbility(defineRulesFor(role));
}
