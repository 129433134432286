import { SelectOptions } from '../components/ui/Form/Controls/Select/Select';
import { AbilityActions, Subjects } from '../config/ability';
import { useAbility } from '../context/abilityContext';

const useFilterAuthorizedOptions = <T>(options: SelectOptions<T>[]) => {
  const ability = useAbility();

  return options.filter((o) =>
    ability.can(AbilityActions.MANAGE, o.value as unknown as Subjects)
  );
};

export default useFilterAuthorizedOptions;
