import { ChangeEventHandler, DragEventHandler, useRef, useState } from 'react';

export interface IUseFileUpload {
  setFile: (file?: File) => void;
}

const useFileUpload = ({ setFile }: IUseFileUpload) => {
  const [dragOver, setDragOver] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const clearUploadFile = () => {
    if (inputRef.current) inputRef.current.value = '';
    setFile(undefined);
    setDragOver(false);
  };

  const dropHandler: DragEventHandler<HTMLDivElement> = (event) => {
    event.nativeEvent.preventDefault();
    if (!event.dataTransfer.files.length) return;
    setFile(event.dataTransfer.files[0]);
  };

  const dragOverHandler: DragEventHandler<HTMLDivElement> = (event) => {
    event.nativeEvent.preventDefault();
    if (!dragOver) setDragOver(true);
  };

  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files?.length) {
      setFile(event.target.files[0]);
    }
  };

  return {
    dropHandler,
    dragOverHandler,
    onChangeHandler,
    clearUploadFile,
    setDragOver,
    dragOver,
    inputRef,
  };
};

export default useFileUpload;
