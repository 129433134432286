import { useMutation, useQuery } from 'react-query';
import { ApiCache } from '../constants/cache';
import { ApiRoutes } from '../constants/routes';
import { User } from '../context/authContext';
import { LocalStorageKeys, removeItemFromStorage } from '../utils/localStorage';
import { client, HttpMethods } from './apiClient';

export interface IUseLoginRequest {
  email: string;
  password: string;
}

export interface IUseLoginResponse {
  data: {
    access: string;
    refresh: string;
    user: User;
  };
}

const useLogin = () => {
  return useMutation((request: IUseLoginRequest) =>
    client<IUseLoginResponse, IUseLoginRequest>(ApiRoutes.LOGIN, {
      data: request,
      method: HttpMethods.POST,
    })
  );
};

export interface IUseForgotPasswordRequest {
  email: string;
}

const useForgotPassword = () => {
  return useMutation((request: IUseForgotPasswordRequest) =>
    client<never, IUseForgotPasswordRequest>(ApiRoutes.FORGOTTEN_PASSWORD, {
      data: request,
      method: HttpMethods.POST,
    })
  );
};

export interface IUseGetUserRequest {
  id: number;
}

export interface IUseGetUserResponse {
  data: User;
}

const useGetUser = (
  token: string | null,
  enabled: boolean,
  onError?: () => void
) => {
  return useQuery(
    [ApiCache.USER],
    () =>
      client<IUseGetUserResponse>(ApiRoutes.GET_CURRENT_USER, {
        method: HttpMethods.GET,
        token: token ?? undefined,
      }),
    {
      enabled: enabled,
      onError: (response) => onError?.(),
    }
  );
};

export interface IUseNewPasswordRequest {
  password: string;
  code: string;
}

const useNewPassword = () => {
  return useMutation((request: IUseNewPasswordRequest) =>
    client<never, IUseNewPasswordRequest>(ApiRoutes.NEW_PASSWORD, {
      data: request,
      method: HttpMethods.POST,
    })
  );
};

export interface IUseGetNewAccessTokenResponse {
  data: {
    access: string;
  };
}

const useGetAccessToken = () => {
  return useMutation(
    (refreshToken: string) =>
      client<IUseGetNewAccessTokenResponse, never>(ApiRoutes.GET_ACCESS_TOKEN, {
        method: HttpMethods.GET,
        token: refreshToken,
      }),
    { onError: (err) => removeItemFromStorage(LocalStorageKeys.REFRESH_TOKEN) }
  );
};

export {
  useLogin,
  useForgotPassword,
  useGetUser,
  useNewPassword,
  useGetAccessToken,
};
