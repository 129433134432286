import { useFormContext } from 'react-hook-form';
import { IDefaultControlProps } from '../../Form';
import Control from '../Control/Control';

interface ITextInputProps extends IDefaultControlProps {
  type?: 'text' | 'email' | 'password';
}

const TextInput = (props: ITextInputProps) => {
  const {
    name,
    label,
    placeholder,
    disabled,
    type = 'text',
    autoComplete,
  } = props;
  const { register, formState } = useFormContext();

  return (
    <Control name={name} label={label} error={formState.errors[name]?.message}>
      <input
        {...register(name)}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autoComplete}
      />
    </Control>
  );
};

export default TextInput;
