import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { isSuccessResponse } from '../../../../api/apiClient';
import { useUpdateUser, useGetUser } from '../../../../api/users';
import { Notifications } from '../../../../constants/notifications';
import {
  CARD_TYPE_OPTIONS,
  ROLES_OPTIONS,
} from '../../../../constants/options';
import { CardType, Roles } from '../../../../constants/user';
import { ValidationMessages } from '../../../../constants/validationMessages';
import { User } from '../../../../context/authContext';
import { useModal } from '../../../../context/modalContext';
import useFilterAuthorizedOptions from '../../../../hooks/useFilterAuthorizedOptions';
import useNotifications from '../../../../hooks/useNotifications';
import { oneOfEnum } from '../../../../utils/helpers';
import Button from '../../../ui/Button/Button';
import Checkbox from '../../../ui/Form/Controls/Checkbox/Checkbox';
import Select from '../../../ui/Form/Controls/Select/Select';
import TextInput from '../../../ui/Form/Controls/TextInput/TextInput';
import Form, { FormOnSubmit } from '../../../ui/Form/Form';

export type UpdateUserFormValues = Pick<
  User,
  'id' | 'role' | 'cardType' | 'cardNumber' | 'email' | 'name' | 'banned'
>;

const validationSchema = Yup.object().shape({
  id: Yup.number().required(),
  role: oneOfEnum(Roles).required(ValidationMessages.REQUIRED),
  cardType: oneOfEnum(CardType).required(ValidationMessages.REQUIRED),
  cardNumber: Yup.string()
    .nullable()
    .when('cardType', {
      is: CardType.CUSTOM,
      then: Yup.string().nullable().required(ValidationMessages.REQUIRED),
    }),
  email: Yup.string()
    .email(ValidationMessages.EMAIL)
    .required(ValidationMessages.REQUIRED),
  name: Yup.string().required(ValidationMessages.REQUIRED),
  banned: Yup.boolean(),
});

const DEFAULT_FORM_VALUES = {
  role: Roles.USER,
  cardType: CardType.NOT_NEEDED,
  email: '',
  name: '',
};

export interface IAddUserForm {
  userId: number;
}

const UpdateUserForm = ({ userId }: IAddUserForm) => {
  const [showCardNumber, setShowCardNumber] = useState<boolean>(false);
  const [showCardType, setShowCardType] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] =
    useState<Partial<UpdateUserFormValues>>(DEFAULT_FORM_VALUES);
  const { closeModal } = useModal();
  const filteredOptions = useFilterAuthorizedOptions(ROLES_OPTIONS);
  const { data: userData } = useGetUser(userId);
  const updateUserMutation = useUpdateUser();
  const notifications = useNotifications();

  useEffect(() => {
    if (userData && isSuccessResponse(userData)) {
      setDefaultValues({
        ...DEFAULT_FORM_VALUES,
        ...userData.data,
      });
      if (!userData.data.cardNumber) setShowCardType(true);
      else setShowCardType(false);
    }
  }, [userData]);

  const handleSubmit: FormOnSubmit<UpdateUserFormValues> = (values) => {
    updateUserMutation.mutate(values, {
      onSuccess: (response) => {
        if (isSuccessResponse(response)) {
          closeModal();
          notifications.success(Notifications.USER_UPDATED);
        } else {
          notifications.handleErrorResponse(response);
        }
      },
    });
  };

  return (
    <div className="bg-white flex flex-col px-8 py-4">
      <h2 className="text-xl font-bold antialiased my-2">
        Felhasználó módosítása
      </h2>
      <Form<UpdateUserFormValues>
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        defaultValues={defaultValues}
        onControlValueChange={(values) => {
          if (values.cardType === CardType.CUSTOM || !values.cardType) {
            setShowCardNumber(true);
          } else setShowCardNumber(false);
        }}
      >
        <div className="p-4">
          <Select label="Jogosultág" name="role" options={filteredOptions} />
          {showCardType ? (
            <Select
              label="Törzsvásárlói kártya"
              name="cardType"
              options={CARD_TYPE_OPTIONS}
            />
          ) : null}
          {showCardNumber || defaultValues.cardNumber ? (
            <TextInput
              name="cardNumber"
              label="Törzsvásárlói kártyaszám"
              disabled={!!defaultValues.cardNumber}
            />
          ) : null}
          <TextInput label="E-mail cím" name="email" />
          <TextInput label="Név" name="name" />
          <Checkbox label="Tiltott" name="banned" />
          <div className="flex justify-between">
            <Button type="submit" variant="primary" className="w-1/3">
              Mentés
            </Button>
            <Button onClick={closeModal} variant="secondary" className="w-1/3">
              Mégse
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default UpdateUserForm;
