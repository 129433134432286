import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ApiCache } from '../constants/cache';
import { ApiRoutes } from '../constants/routes';
import { useClient } from '../context/authContext';
import { HttpMethods, isSuccessResponse } from './apiClient';

export interface IImageListItem {
  id: number;
  position: number;
  fileName: string;
}

export interface IUseGetImagesInfoResponse {
  list: IImageListItem[];
}

const useGetImagesInfo = () => {
  const client = useClient();

  return useQuery([ApiCache.IMAGES_INFO], () =>
    client<IUseGetImagesInfoResponse>(ApiRoutes.IMAGES_INFO, {
      method: HttpMethods.GET,
    })
  );
};

const useDownloadImage = (id?: number) => {
  const client = useClient();

  return useQuery(
    [ApiCache.IMAGE, { id }],
    () =>
      client<Blob>(`${ApiRoutes.DOWNLOAD_IMAGE}/${id}`, {
        method: HttpMethods.GET,
        responseType: 'blob',
      }),
    {
      enabled: false,
    }
  );
};

export interface IUseDeleteImageResponse {
  id: number;
}

const useDeleteImage = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    (id: number) =>
      client<IUseDeleteImageResponse>(`${ApiRoutes.DELETE_IMAGE}/${id}`, {
        method: HttpMethods.DELETE,
      }),
    {
      onSuccess: (response) => {
        if (isSuccessResponse(response)) {
          queryClient.invalidateQueries([ApiCache.IMAGE, { id: response.id }]);
          queryClient.invalidateQueries([ApiCache.IMAGES_INFO]);
        }
      },
    }
  );
};

export interface IUseUploadImageResponse {
  data: number;
}

const useUploadImage = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    ({ image, position }: { image: File; position: number }) => {
      const formData = new FormData();
      formData.append('file', image);
      formData.append('position', '' + position);

      return client<IUseUploadImageResponse, FormData>(ApiRoutes.UPLOAD_IMAGE, {
        data: formData,
        method: HttpMethods.POST,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    {
      onSuccess: () => queryClient.invalidateQueries([ApiCache.IMAGES_INFO]),
    }
  );
};

export { useGetImagesInfo, useDownloadImage, useDeleteImage, useUploadImage };
