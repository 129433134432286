import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import React from 'react';
import { Notifications } from '../constants/notifications';
import { ApiRoutes } from '../constants/routes';
import { getItemFromStorage, LocalStorageKeys } from '../utils/localStorage';

export type ISuccessResponse<T> = T & {
  status: 'OK';
};

export interface IErrorResponse {
  status: 'ERROR';
  messages: (keyof typeof Notifications | string)[];
}

type ApiResponse<T> = ISuccessResponse<T> | IErrorResponse;

export const isApiResponse = (x: unknown): x is ApiResponse<unknown> => {
  return (
    typeof x === 'object' &&
    !Array.isArray(x) &&
    x !== null &&
    (x as { status: string }).status !== undefined
  );
};

export const isSuccessResponse = <T>(
  response?: ApiResponse<T>
): response is ISuccessResponse<T> => {
  return response?.status === 'OK';
};

export enum HttpMethods {
  POST = 'post',
  GET = 'get',
  PATCH = 'patch',
  PUT = 'put',
  DELETE = 'delete',
}
export interface IHttpRequestConfig<T> extends AxiosRequestConfig<T> {
  method: HttpMethods;
  headers?: Record<string, string>;
  data?: T;
  token?: string;
}

/**
 * API request with 2 generic types: R is the response type, T is the request data object type.
 * @param endpoint - URL that comes after the baseurl see {@link EndPoints}
 * @param config - the request object see {@link IHttpRequestConfig}
 * @returns Promise<R>
 */
const client = async <R = ApiResponse<unknown>, T = undefined>(
  endpoint: string,
  {
    data,
    method,
    headers = {
      'Content-Type': 'application/json',
    },
    token,
    ...rest
  }: IHttpRequestConfig<T>,
  setToken?: React.Dispatch<React.SetStateAction<string | null>>,
  logout?: () => void
): Promise<ApiResponse<R>> => {
  const config: AxiosRequestConfig<T> = {
    method,
    data,
    baseURL: process.env.REACT_APP_API_URL,
    url: endpoint,
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      ...headers,
    },
    ...rest,
  };

  const originalRequest = axios(config).then(
    (response: AxiosResponse<ApiResponse<R>>) => {
      return response.data;
    }
  );

  return originalRequest.catch((err) => {
    if (err.response.status === 401) {
      const REFRESH_TOKEN_CONFIG: AxiosRequestConfig = {
        method: HttpMethods.GET,
        baseURL: process.env.REACT_APP_API_URL,
        url: ApiRoutes.GET_ACCESS_TOKEN,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getItemFromStorage(
            LocalStorageKeys.REFRESH_TOKEN
          )}`,
        },
      };
      return axios(REFRESH_TOKEN_CONFIG)
        .then((response) => {
          if (response.data.data.access) {
            if (setToken) setToken(response.data.data.access);
            return axios({
              ...config,
              headers: {
                ...headers,
                Authorization: `Bearer ${response.data.data.access}`,
              },
            })
              .then((r: AxiosResponse<ApiResponse<R>>) =>
                Promise.resolve(r.data)
              )
              .catch((error) => {
                if (logout) logout();
                return Promise.reject(error);
              });
          }
          if (logout) logout();
          return Promise.reject();
        })
        .catch((e) => {
          if (logout) logout();
          return Promise.reject(e);
        });
    }
    return Promise.reject(err);
  });
};

export { client, axios };
