import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { isSuccessResponse } from '../../../api/apiClient';
import { useNewPassword } from '../../../api/authentication';
import { AppRoutes } from '../../../constants/routes';
import { ValidationMessages } from '../../../constants/validationMessages';
import { assertValue } from '../../../utils/helpers';
import Button from '../../ui/Button/Button';
import TextInput from '../../ui/Form/Controls/TextInput/TextInput';
import Form, { FormOnSubmit } from '../../ui/Form/Form';

interface INewPasswordForm {
  password: string;
  confirmPassword: string;
}

const validationSchema = Yup.object().shape({
  password: Yup.string().required(ValidationMessages.REQUIRED),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], ValidationMessages.PASSWORD_MATCH)
    .required(ValidationMessages.REQUIRED),
});

const NewPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const newPasswordMutation = useNewPassword();

  useEffect(() => {
    if (searchParams.get('code') === null) {
      navigate(AppRoutes.LOGIN);
    }
  }, [navigate, searchParams]);

  const onSubmit: FormOnSubmit<INewPasswordForm> = (data) => {
    const { password } = data;
    const code = searchParams.get('code');
    assertValue(code);
    newPasswordMutation.mutate(
      { password, code },
      {
        onSuccess: (response) => {
          if (isSuccessResponse(response)) {
            // TODO handle userlogin
            navigate(AppRoutes.LOGIN);
          }
        },
      }
    );
  };

  return (
    <Form<INewPasswordForm>
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <TextInput type="password" name="password" label="Új jelszó" />
      <TextInput
        type="password"
        name="confirmPassword"
        label="Új jelszó mégegyszer"
      />
      <Button fullWidth type="submit" loading={newPasswordMutation.isLoading}>
        Új jelszó beállítása
      </Button>
    </Form>
  );
};

export default NewPassword;
