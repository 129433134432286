import { MutableRefObject, useEffect } from 'react';

export interface IUseIntersectionObserverParams {
  root?: MutableRefObject<Element | Document | null | undefined> | null;
  target: MutableRefObject<Element | null | undefined>;
  onIntersect: () => void;
  threshold?: number;
  rootMargin?: string;
  enabled?: boolean;
}

export type UseIntersectionObserver = (
  params: IUseIntersectionObserverParams
) => void;

const useIntersectionObserver: UseIntersectionObserver = ({
  root = null,
  target,
  onIntersect,
  threshold = 1.0,
  rootMargin = '0px',
  enabled = true,
}) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && onIntersect()),
      {
        root: root && root.current,
        rootMargin,
        threshold,
      }
    );

    const el = target && target.current;

    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [target.current, enabled]);
};

export default useIntersectionObserver;
