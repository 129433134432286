import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useModal } from '../../../../context/modalContext';

export interface IModalOverlay {
  children: React.ReactNode;
}

const ModalOverlay = ({ children }: IModalOverlay) => {
  const { closeModal } = useModal();

  return (
    <div
      className={`overflow-visible h-5/6 md:h-[600px] relative w-11/12 max-w-2xl bg-primary-default p-4 rounded-2xl max-h-full`}
    >
      <button
        className={`bg-none border-none text-white text-3xl absolute -top-3 -right-1 cursor-pointer z-20`}
        onClick={closeModal}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className="overflow-auto h-full bg-white">{children}</div>
    </div>
  );
};

export default ModalOverlay;
