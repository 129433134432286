import { useFormContext } from 'react-hook-form';
import { IDefaultControlProps } from '../../Form';
import Control from '../Control/Control';

const Checkbox = (props: IDefaultControlProps) => {
  const { name, label, placeholder, disabled, autoComplete } = props;
  const { register, formState } = useFormContext();

  return (
    <Control name={name} label={label} error={formState.errors[name]?.message}>
      <input
        {...register(name)}
        className="h-6 w-6 accent-primary-default"
        type="checkbox"
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autoComplete}
      />
    </Control>
  );
};

export default Checkbox;
