export enum Roles {
  ADMIN = 'ADMIN',
  EDITOR = 'EDITOR',
  USER = 'USER',
}

export enum CardType {
  NOT_NEEDED = 'NOT_NEEDED',
  GENERATED = 'GENERATED',
  CUSTOM = 'CUSTOM',
}
