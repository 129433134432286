import { format } from 'date-fns';
import { useQuery } from 'react-query';
import qs from 'qs';
import { ApiCache } from '../constants/cache';
import { useClient } from '../context/authContext';
import { getQueryWithParams } from '../utils/helpers';
import { HttpMethods } from './apiClient';
import { ApiRoutes } from '../constants/routes';

export interface IUseGetDashboardCardDataResponse {
  data: number | string;
}

export interface IUseGetDashboardCardDataRequest {
  dateFrom?: Date;
}

const useGetFreeCards = () => {
  const client = useClient();

  return useQuery([ApiCache.FREE_CARDS], () =>
    client<IUseGetDashboardCardDataResponse>(ApiRoutes.DASHBOARD_FREE_CARDS, {
      method: HttpMethods.GET,
    })
  );
};

const useGetUsersCount = ({ dateFrom }: IUseGetDashboardCardDataRequest) => {
  const client = useClient();
  const parsed = {
    dateFrom: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : dateFrom,
  };
  const filterQuery = getQueryWithParams(parsed, true);
  const params = qs.stringify(filterQuery);

  return useQuery([ApiCache.USERS_COUNT], () =>
    client<IUseGetDashboardCardDataResponse>(
      `${ApiRoutes.DASHBOARD_USERS}?${params}`,
      {
        method: HttpMethods.GET,
      }
    )
  );
};

const useGetCustomers = () => {
  const client = useClient();

  return useQuery([ApiCache.CUSTOMERS], () =>
    client<IUseGetDashboardCardDataResponse>(ApiRoutes.DASHBOARD_CUSTOMERS, {
      method: HttpMethods.GET,
    })
  );
};

const useGetAppUsage = ({ dateFrom }: IUseGetDashboardCardDataRequest) => {
  const client = useClient();
  const parsed = {
    dateFrom: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : dateFrom,
  };
  const filterQuery = getQueryWithParams(parsed, true);
  const params = qs.stringify(filterQuery);

  return useQuery([ApiCache.APP_USAGE], () =>
    client<IUseGetDashboardCardDataResponse>(
      `${ApiRoutes.DASHBOARD_APP_USAGE}?${params}`,
      {
        method: HttpMethods.GET,
      }
    )
  );
};

const useGetTileViews = ({ dateFrom }: IUseGetDashboardCardDataRequest) => {
  const client = useClient();
  const parsed = {
    dateFrom: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : dateFrom,
  };
  const filterQuery = getQueryWithParams(parsed, true);
  const params = qs.stringify(filterQuery);

  return useQuery([ApiCache.TILE_VIEWS], () =>
    client<IUseGetDashboardCardDataResponse>(
      `${ApiRoutes.DASHBOARD_TILE_VIEWS}?${params}`,
      {
        method: HttpMethods.GET,
      }
    )
  );
};

const useGetCollectedPrizes = ({
  dateFrom,
}: IUseGetDashboardCardDataRequest) => {
  const client = useClient();
  const parsed = {
    dateFrom: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : dateFrom,
  };
  const filterQuery = getQueryWithParams(parsed, true);
  const params = qs.stringify(filterQuery);

  return useQuery([ApiCache.COLLECTED_PRIZES], () =>
    client<IUseGetDashboardCardDataResponse>(
      `${ApiRoutes.DASHBOARD_COLLECTED_PRIZES}?${params}`,
      {
        method: HttpMethods.GET,
      }
    )
  );
};

export {
  useGetFreeCards,
  useGetCustomers,
  useGetUsersCount,
  useGetAppUsage,
  useGetTileViews,
  useGetCollectedPrizes,
};
