import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Order } from '../../../../types/table';

export interface ISortableHeaderProps {
  id: string;
  label: string;
  currentSortId: string;
  currentSortOrder: Order;
  handleClick: (...args: any[]) => void;
}

const SortableHeader = ({
  id,
  currentSortId,
  currentSortOrder,
  label,
  handleClick,
}: ISortableHeaderProps) => {
  return (
    <span
      className="flex items-center cursor-pointer"
      onClick={() => handleClick()}
    >
      <span className="mr-2">{label}</span>
      <div className="flex flex-col">
        <FontAwesomeIcon
          size="xs"
          icon={faChevronUp}
          className={
            currentSortId === id && currentSortOrder === 'ASC'
              ? 'opacity-100'
              : 'opacity-30'
          }
        />
        <FontAwesomeIcon
          size="xs"
          icon={faChevronDown}
          className={
            currentSortId === id && currentSortOrder === 'DESC'
              ? 'opacity-100'
              : 'opacity-30'
          }
        />
      </div>
    </span>
  );
};

export default SortableHeader;
