export enum LocalStorageKeys {
  LOCAL_STORAGE_AUTH_KEY = 'tvm_token',
  SIDEBAR_STATE = 'tvm_sidebar',
  REFRESH_TOKEN = 'tvm_refresh_token',
}

export const setItemToStorage = (key: LocalStorageKeys, item: unknown) => {
  window.localStorage.setItem(key, JSON.stringify(item));
};

export const getItemFromStorage = <T>(key: LocalStorageKeys): T | null => {
  const value = window.localStorage.getItem(key);

  return value === 'undefined' || value === null ? null : JSON.parse(value);
};

export const removeItemFromStorage = (key: LocalStorageKeys) =>
  window.localStorage.removeItem(key);
