import { useState } from 'react';
import * as Yup from 'yup';
import { isSuccessResponse } from '../../../../api/apiClient';
import { useCreateUser } from '../../../../api/users';
import { Notifications } from '../../../../constants/notifications';
import {
  CARD_TYPE_OPTIONS,
  ROLES_OPTIONS,
} from '../../../../constants/options';
import { CardType, Roles } from '../../../../constants/user';
import { ValidationMessages } from '../../../../constants/validationMessages';
import { User } from '../../../../context/authContext';
import { useModal } from '../../../../context/modalContext';
import useFilterAuthorizedOptions from '../../../../hooks/useFilterAuthorizedOptions';
import useNotifications from '../../../../hooks/useNotifications';
import { oneOfEnum } from '../../../../utils/helpers';
import Button from '../../../ui/Button/Button';
import Select from '../../../ui/Form/Controls/Select/Select';
import TextInput from '../../../ui/Form/Controls/TextInput/TextInput';
import Form, { FormOnSubmit } from '../../../ui/Form/Form';

export type AddUserFormValues = Pick<
  User,
  'role' | 'cardType' | 'cardNumber' | 'email' | 'password' | 'name' | 'banned'
> & { password: string };

const validationSchema = Yup.object().shape({
  role: oneOfEnum(Roles).required(ValidationMessages.REQUIRED),
  cardType: oneOfEnum(CardType).required(ValidationMessages.REQUIRED),
  cardNumber: Yup.string().when('cardType', {
    is: CardType.CUSTOM,
    then: Yup.string().required(ValidationMessages.REQUIRED),
  }),
  email: Yup.string()
    .email(ValidationMessages.EMAIL)
    .required(ValidationMessages.REQUIRED),
  password: Yup.string().required(ValidationMessages.REQUIRED),
  name: Yup.string().required(ValidationMessages.REQUIRED),
  banned: Yup.boolean(),
});

export interface IAddUserForm {
  userId?: number;
}

const AddUserForm = ({ userId }: IAddUserForm) => {
  const [showCardNumber, setShowCardNumber] = useState<boolean>(false);
  const { closeModal } = useModal();
  const createUserMutation = useCreateUser();
  const notifications = useNotifications();
  const filteredOptions = useFilterAuthorizedOptions(ROLES_OPTIONS);

  const handleSubmit: FormOnSubmit<AddUserFormValues> = (values) => {
    createUserMutation.mutate(values, {
      onSuccess: (response) => {
        if (isSuccessResponse(response)) {
          closeModal();
          notifications.success(Notifications.USER_CREATED);
        } else {
          notifications.handleErrorResponse(response);
        }
      },
    });
  };

  return (
    <div className="bg-white flex flex-col px-8 py-4">
      <h2 className="text-xl font-bold antialiased my-2">
        Felhasználó felvétele
      </h2>
      <Form<AddUserFormValues>
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        onControlValueChange={(values) => {
          if (values.cardType === CardType.CUSTOM) setShowCardNumber(true);
          else setShowCardNumber(false);
        }}
      >
        <div className="p-4">
          <Select label="Jogosultág" name="role" options={filteredOptions} />
          <Select
            label="Törzsvásárlói kártya"
            name="cardType"
            options={CARD_TYPE_OPTIONS}
          />
          {showCardNumber ? (
            <TextInput name="cardNumber" label="Törzsvásárlói kártyaszám" />
          ) : null}
          <TextInput label="E-mail cím" name="email" />
          <TextInput type="password" label="Jelszó" name="password" />
          <TextInput label="Név" name="name" />
          <div className="flex justify-between">
            <Button type="submit" variant="primary" className="w-1/3">
              Mentés
            </Button>
            <Button onClick={closeModal} variant="secondary" className="w-1/3">
              Mégse
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddUserForm;
