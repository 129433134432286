import { useEffect, useState } from 'react';
import useFileUpload from '../../../ui/FileUpload/useFileUpload';
import { ITileInfoProps } from './TileInfo';
import useTile from './useTile';

export const useTileInfo = (imageInfo: ITileInfoProps['imageInfo']) => {
  const { src } = useTile(imageInfo);
  const [imageFile, setImageFile] = useState<File>();
  const [imageFileSrc, setImageFileSrc] = useState<string>('');
  const [isNewUpload, setIsNewUpload] = useState<boolean>(!src);

  useEffect(() => {
    if (imageInfo.id) setIsNewUpload(false);
    else setIsNewUpload(true);
  }, [src]);

  const setUploadFile = (file?: File) => {
    if (file === undefined) {
      setImageFile(undefined);
      setImageFileSrc('');
    }
    if (file?.type.split('/')[0] !== 'image') return;
    setImageFile(file);
    setImageFileSrc(URL.createObjectURL(file));
  };

  const {
    clearUploadFile,
    dragOver,
    dragOverHandler,
    dropHandler,
    inputRef,
    onChangeHandler,
    setDragOver,
  } = useFileUpload({ setFile: setUploadFile });

  return {
    src: isNewUpload ? (imageFile ? imageFileSrc : '') : src,
    dropHandler,
    dragOverHandler,
    onChangeHandler,
    clearUploadFile,
    setDragOver,
    setIsNewUpload,
    dragOver,
    isNewUpload,
    inputRef,
    imageFile,
  };
};
