import PageContainer from '../../ui/PageContainer/PageContainer';
import Card from './components/Card';
import useDashboard from './useDashboard';

const Dashboard = () => {
  const dashboardData = useDashboard();
  return (
    <PageContainer title="Dashboard">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        {dashboardData.map((d) => (
          <div key={d.label}>
            <h2>{d.label}</h2>
            <hr className="m-splitLine" />
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-2">
              {d.cards.map((c) => (
                <Card {...c} key={c.key} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </PageContainer>
  );
};

export default Dashboard;
