import { QueryClient, QueryClientProvider } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { Notifications } from '../constants/notifications';
import { IContextProvider } from './appProviders';

export const queryClient = new QueryClient();

const ClientProvider = ({ children }: IContextProvider) => {
  const { addToast } = useToasts();
  const generalErrorHandling = (error: unknown) => {
    if (error instanceof Error) {
      const message =
        error.message === 'Request failed with status code 403'
          ? Notifications.AUTHORIZATION_ERROR
          : error.message;
      addToast(message, { appearance: 'error', autoDismiss: false });
    } else {
      addToast(JSON.stringify(error), {
        appearance: 'error',
        autoDismiss: false,
      });
    }
  };

  queryClient.setDefaultOptions({
    mutations: {
      onError: generalErrorHandling,
    },
    queries: {
      onError: generalErrorHandling,
      suspense: true,
    },
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default ClientProvider;
